import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6" }
const _hoisted_3 = { class: "w-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableKpiType = _resolveComponent("EditableKpiType")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpiTypes, (kpiType) => {
        return (_openBlock(), _createBlock(_component_EditableKpiType, {
          key: kpiType.id,
          selected: kpiType.selected,
          "kpi-type": kpiType,
          onClick: ($event: any) => (_ctx.kpiTypeClicked(kpiType))
        }, null, 8, ["selected", "kpi-type", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_CustomCheckbox, {
        checked: _ctx.applyToAllCompanies,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.applyToAllCompanies) = $event)),
        label: _ctx.$t('views.settings.kpis.content.applyToAllCompaniesLabel')
      }, null, 8, ["checked", "label"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomButton, {
        loading: _ctx.disableButtons,
        onClick: _ctx.updateKpiPreferences
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.kpis.content.editButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ])
  ]))
}