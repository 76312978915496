<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.settings.users.content.table.title') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.settings.users.content.table.searchFieldLabel')"></SearchInput>
            </span>
            <SettingsModal v-if="canManageDirectory && showInviteUsersButton">
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.settings.users.content.modals.create.createModalButtonLabel') }}
                    </AddButton>
                </template>
                <template #form="{ close }">
                    <CreateUserForm @cancel="close" @submit="close"></CreateUserForm>
                </template>
            </SettingsModal>
        </div>
        <CustomTable :headers="headers" :items="filteredItems">
            <template #header_actions>
                <th class="w-6"></th>
            </template>

            <template #name="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    {{ `${item.first_name} ${item.last_name}` }}
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-6 my-auto">
                    <span class="flex justify-end">
                        <SettingsModal v-if="canManageDirectory">
                            <template #default="{ open }">
                                <button type="button" class="p-2 -mr-2" @click="open">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </template>
                            <template #form="{ close }">
                                <EditUserForm :directory-user="item" @cancel="close" @submit="close" />
                            </template>
                        </SettingsModal>
                    </span>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { TableHeader, DirectoryUser, Module, IconSource } from '@/types';
import SettingsModal from '@/views/dashboard/settings/SettingsModal.vue';
import CreateUserForm from '@/views/dashboard/settings/users/forms/CreateUserForm.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import AddButton from '@/components/ui/AddButton.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import useFilterableTable from '@/composables/useFilterableTable';
import EditUserForm from './forms/EditUserForm.vue';

export default defineComponent({
    components: { MainViewCard, CustomTable, SettingsModal, AddButton, CreateUserForm, EditUserForm, SearchInput },
    setup() {
        store.dispatch(ActionType.GetDirectoryUsers);
        store.dispatch(ActionType.GetDirectoryFarms);
        store.dispatch(ActionType.GetDirectoryRoles);
        store.dispatch(ActionType.GetDirectoryPermissions);

        const headers = computed<TableHeader[]>(() => [
            {
                text: i18n.global.t('views.settings.users.content.table.headers.name'),
                value: 'name',
                align: 'left',
                sortable: true,
                sortComparison: (a: DirectoryUser, b: DirectoryUser, orderMultiplier: number) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`) * orderMultiplier,
                searchComparison: (item: any, search: any) => `${item.first_name} ${item.last_name}`.toLowerCase().trim().includes(search.toLowerCase().trim()),
                searchable: true,
            },
            {
                text: i18n.global.t('views.settings.users.content.table.headers.email'),
                value: 'email',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                searchable: false,
            },
        ]);
        const items = computed(() => store.state.directoryUsers);
        const { search, filteredItems } = useFilterableTable(items, headers);

        const showInviteUsersButton = computed(() => store.getters.hasModuleEnabled(Module.UserManagement));
        const canManageDirectory = computed(() => store.getters.loggedInUserCanManageDirectory);

        return {
            showInviteUsersButton,
            search,
            filteredItems,
            headers,
            canManageDirectory,
            IconSource,
        };
    },
});
</script>
