<template>
    <div class="grid">
        <label :class="[{ hoverable: hoverable, tabbable: tabbable, 'cursor-pointer': !disabled, indeterminate }]" class="checkbox bounce flex" @click.stop>
            <input
                :id="uid + '_input'"
                v-model="computedChecked"
                :class="[cursorClass]"
                :disabled="disabled"
                :tabindex="tabbable ? undefined : '-1'"
                type="checkbox"
                @keydown.enter="computedChecked = !computedChecked"
            />
            <svg viewBox="0 0 21 21" aria-hidden="true">
                <polyline v-if="!indeterminate" points="5 10.75 8.5 14.25 16 6" />
                <line v-else x1="5" y1="10.75" x2="16" y2="10.75" />
            </svg>
            <span v-if="label" :class="labelClass" class="ml-3 -mt-1">{{ label }}</span>
        </label>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        labelClass: {
            type: String,
            default: 'text-dark-gray-600',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        indeterminate: {
            type: Boolean,
            default: false,
        },
        tabbable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hoverable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:checked'],
    data() {
        return {
            uid: Math.random().toString(36).substring(6),
        };
    },
    computed: {
        cursorClass(): string {
            return this.disabled ? '' : 'cursor-pointer';
        },
        computedChecked: {
            get(): boolean {
                return this.checked;
            },
            set(newValue: boolean) {
                this.$emit('update:checked', newValue);
            },
        },
    },
});
</script>

<style>
.checkbox.tabbable input:focus {
    outline: auto !important;
}

.checkbox {
    --background: #fff;
    --border: #989a9a;
    --border-hover: theme('colors.primary.500');
    --border-active: theme('colors.primary.700');
    --border-indeterminate: theme('colors.primary.700');
    --tick: #fff;
    --tick-indeterminate: #fff;
    --checkbox-size: 15px;
    position: relative;
}

.checkbox input,
.checkbox svg,
.checkbox line {
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    display: block;
}

.checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0 0 0 4px;
    padding: 0;
    border-radius: 2px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}

.checkbox.hoverable input:hover {
    --s: 2px;
    --b: var(--border-hover);
}

.checkbox input:checked {
    --b: var(--border-active);
}

.indeterminate.checkbox input {
    --b: var(--border-indeterminate);
}

.checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 4px;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox path {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    transition: stroke-dasharray 0.3s, stroke-dashoffset 0.3s;
}

.checkbox.line input:checked + svg line,
.indeterminate.checkbox.line input + svg line {
    stroke-dasharray: 22;
    stroke-dashoffset: 0;
    transition: stroke-dasharray 0.3s, stroke-dashoffset 0.3s;
}

.checkbox.bounce {
    --stroke: var(--tick);
}

.indeterminate.checkbox.bounce {
    --stroke: var(--tick-indeterminate);
}

.checkbox.bounce input:checked,
.indeterminate.checkbox input {
    --s: 11px;
}

.checkbox.bounce input:checked + svg,
.indeterminate.checkbox input + svg {
    animation: bounce 0.2s linear forwards 0.2s;
}

.checkbox.bounce svg {
    --scale: 0;
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
</style>
