import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { store } from '@/plugins/store';

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path !== '/settings/companies' && !store.getters.hasActiveDirectory) {
        next('/settings/companies');
    } else {
        next();
    }
});

export default router;
