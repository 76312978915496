<template>
    <div class="space-y-6">
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
            <EditableKpiType v-for="kpiType of kpiTypes" :key="kpiType.id" :selected="kpiType.selected" :kpi-type="kpiType" @click="kpiTypeClicked(kpiType)"></EditableKpiType>
        </div>
        <div>
            <CustomCheckbox v-model:checked="applyToAllCompanies" :label="$t('views.settings.kpis.content.applyToAllCompaniesLabel')" />
        </div>
        <div class="w-40">
            <CustomButton :loading="disableButtons" @click="updateKpiPreferences">
                {{ $t('views.settings.kpis.content.editButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import cloneDeep from 'lodash-es/cloneDeep';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { KpiType, KpiPreference } from '@/types';
import EditableKpiType from '@/views/dashboard/settings/kpis/EditableKpiType.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';

export default defineComponent({
    components: { EditableKpiType, CustomButton, CustomCheckbox },
    props: {
        isWeaners: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            MAX_NUMBER_OF_KPIS: 4,
            MIN_NUMBER_OF_KPIS: 4,
            chosenKpiPreferences: [] as KpiPreference[],
            toast: useToast(),
            disableButtons: false,
            applyToAllCompanies: false,
        };
    },
    computed: {
        kpiTypes(): KpiType[] {
            return cloneDeep(store.state.kpiTypes)
                .filter((kpiType) => kpiType.is_weaner === null || kpiType.is_weaner === this.isWeaners)
                .map((kpiType) => {
                    const selected = this.chosenKpiPreferences.find((currentKpiPreference) => currentKpiPreference.type_id === kpiType.id);
                    return { ...kpiType, selected: Boolean(selected) };
                });
        },
        kpiPreferences() {
            return store.state.kpiPreferences;
        },
    },
    watch: {
        kpiPreferences: {
            handler(newValue: { is_weaner: boolean; directory_default: boolean; kpis: KpiPreference[] }[] | null) {
                const preferences = newValue?.find((kpiPreference) => this.isWeaners === kpiPreference.is_weaner);

                if (preferences) {
                    this.applyToAllCompanies = preferences.directory_default ?? false;
                    this.chosenKpiPreferences = preferences.kpis ?? [];
                }
            },
            immediate: true,
        },
    },
    methods: {
        kpiTypeClicked(kpiType: KpiType) {
            if (kpiType.selected) {
                this.chosenKpiPreferences = this.chosenKpiPreferences.filter((kpiPreference) => kpiPreference.type_id !== kpiType.id);
            } else {
                this.chosenKpiPreferences = [...this.chosenKpiPreferences, { type_id: kpiType.id, index: this.chosenKpiPreferences.length - 1 }];
            }
        },
        async updateKpiPreferences() {
            const selectedPreferences = this.chosenKpiPreferences.filter((p) => this.kpiTypes.some((kpiType) => kpiType.id === p.type_id));

            if (selectedPreferences.length > this.MAX_NUMBER_OF_KPIS) {
                this.toast.error(this.$t('views.settings.kpis.content.maxAllowedKpisWarning').replace('$NUMBER', `${this.MAX_NUMBER_OF_KPIS}`));
                return;
            }
            if (selectedPreferences.length < this.MIN_NUMBER_OF_KPIS) {
                this.toast.error(this.$t('views.settings.kpis.content.minAllowedKpisWarning').replace('$NUMBER', `${this.MIN_NUMBER_OF_KPIS}`));
                return;
            }
            if (this.disableButtons) {
                return;
            }

            try {
                this.disableButtons = true;

                const payload = {
                    is_weaner: this.isWeaners,
                    directory_default: this.applyToAllCompanies,
                    kpis: selectedPreferences.map((preference, index) => ({ type_id: preference.type_id, index })),
                };

                await store.dispatch(ActionType.PutKpiPreferences, { form: payload as any });

                store.dispatch(ActionType.GetKpiPreferences, { options: { ignoreCache: true } });
                store.dispatch(ActionType.GetKpis, { options: { ignoreCache: true } });

                this.toast.success(this.$t('views.settings.kpis.content.updatedSettingsSuccess'));
            } catch (err) {
                //
            } finally {
                this.disableButtons = false;
            }
        },
    },
});
</script>
