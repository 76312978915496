import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-10"
}
const _hoisted_2 = { class: "space-y-6" }
const _hoisted_3 = { class: "text-lg font-semibold" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "text-lg font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableKpiGrid = _resolveComponent("EditableKpiGrid")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.settings.kpis.header.heading'),
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      (_ctx.kpiPreferences)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('views.settings.kpis.content.headings.weaners')), 1),
              _createVNode(_component_EditableKpiGrid, { "is-weaners": "" })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('views.settings.kpis.content.headings.slaughterPigs')), 1),
              _createVNode(_component_EditableKpiGrid)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["heading"]))
}