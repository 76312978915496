<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.analysis.header.heading')" :has-period-picker="true" :has-pig-type-picker="false">
        <div class="space-y-4">
            <div class="relative">
                <Listbox v-slot="{ open }" v-model="selectedAnalysisType">
                    <ListboxButton class="w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left">
                        <div class="flex items-center gap-x-1 leading-normal">
                            <span class="font-medium">{{ $t('views.analysis.fields.category.label') }}</span>
                            <span class="text-gray-500">
                                {{ formattedAnalysisTypes.find((t) => t.value === selectedAnalysisType)?.label || $t('views.analysis.fields.category.texts.noSelection') }}
                            </span>
                        </div>
                        <ChevronDown class="flex-shrink-0 h-5 w-5 transition-transform" :class="{ 'rotate-180': open }" aria-hidden="true" />
                    </ListboxButton>
                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-100 ease-out"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <ListboxOptions class="absolute z-20 mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <ListboxOption v-for="type in formattedAnalysisTypes" :key="type.value" v-slot="{ active, selected }" :value="type.value">
                                <li
                                    class="py-2 px-3 h-full truncate block w-full text-left cursor-pointer"
                                    :class="{
                                        'bg-primary-300 bg-opacity-40': selected,
                                        'hover:bg-gray-100': !selected,
                                        'bg-gray-100': active && !selected,
                                    }"
                                >
                                    {{ type.label }}
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </Listbox>
            </div>
            <Popover v-model:open="isLocationPickerOpen">
                <PopoverTrigger as-child>
                    <button class="w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left">
                        <div class="flex items-center gap-x-1 leading-normal">
                            <span class="font-medium">{{ $t('views.analysis.fields.locations.label') }}</span>
                            <span class="text-gray-500">
                                {{ countText }}
                            </span>
                        </div>
                        <ChevronDown class="flex-shrink-0 h-5 w-5 transition-transform" :class="{ 'rotate-180': isLocationPickerOpen }" aria-hidden="true" />
                    </button>
                </PopoverTrigger>
                <PopoverContent trigger-width align="start" class="flex flex-col p-0 h-[516px] overflow-hidden">
                    <div class="flex-shrink-0 flex justify-between gap-x-4 px-3 py-4">
                        <RadioGroup :model-value="pigType" @update:model-value="togglePigType">
                            <div v-for="option in pigTypeOptions" :key="option.value" class="flex items-center cursor-pointer">
                                <RadioGroupItem :id="`option-id-${option.value}`" :value="option.value" />
                                <Label :for="`option-id-${option.value}`" class="font-normal cursor-pointer pl-2">{{ option.label }}</Label>
                            </div>
                        </RadioGroup>
                        <div class="flex items-center gap-x-1 pr-2">
                            <label class="flex cursor-pointer items-center gap-x-1.5">
                                <CustomCheckbox v-model:checked="includeCompleteBatch" />
                                <span>{{ $t('views.analysis.fields.includeAllBatchData.label') }}</span>
                            </label>
                            <TooltipProvider :delay-duration="0">
                                <Tooltip>
                                    <TooltipTrigger as-child>
                                        <button type="button" class="flex items-center justify-center p-1 rounded-full transition-colors hover:bg-gray-100">
                                            <span class="sr-only">{{ $t('views.analysis.fields.includeAllBatchData.label') }}</span>
                                            <Icon class="h-6 w-auto" :src="IconSource.HelpCircleIcon"></Icon>
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent :collision-padding="12">
                                        <p>{{ $t('views.analysis.fields.includeAllBatchData.tooltip') }}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    </div>
                    <div class="flex-shrink-0 h-px w-full bg-gray-200" />
                    <div role="tree" aria-orientation="horizontal" class="flex flex-grow divide-x max-h-full overflow-hidden">
                        <div class="flex flex-col items-stretch w-1/3 flex-shrink-0" role="group">
                            <div class="sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2">{{ $t('views.analysis.fields.locations.groups.farms') }}</div>
                            <div class="max-h-full overflow-y-auto">
                                <div
                                    v-for="(farm, farmIndex) in locationOptions"
                                    :key="farm.farm_id"
                                    class="flex items-center cursor-pointer"
                                    role="treeitem"
                                    :aria-label="farm.name"
                                    aria-level="1"
                                    :aria-expanded="selectedActiveFarmIndex === farmIndex"
                                    :aria-setsize="locationOptions.length"
                                    :aria-posinset="farmIndex + 1"
                                    tabindex="0"
                                    @keydown.enter="selectActiveFarm(farmIndex)"
                                    @mouseover="selectActiveFarm(farmIndex)"
                                    @click="selectActiveFarm(farmIndex)"
                                >
                                    <label
                                        class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors"
                                        :class="{
                                            'hover:bg-gray-100/70 focus:bg-gray-100/70': locationOptions[farmIndex]?.barns?.length && selectedActiveFarmIndex !== farmIndex,
                                            'bg-primary-300 bg-opacity-30': selectedActiveFarmIndex === farmIndex,
                                        }"
                                    >
                                        <CustomCheckbox
                                            :checked="farm.checked"
                                            :indeterminate="farm.indeterminate"
                                            disable-transition
                                            class="flex-shrink-0"
                                            @update:checked="
                                                () => {
                                                    hasMadeSelection = true;
                                                    toggleFarm({ farm });
                                                }
                                            "
                                        />
                                        <div class="flex grow gap-x-3 items-center justify-between max-w-full min-w-0">
                                            <span class="grow select-none truncate">{{ farm.name }}</span>
                                            <ChevronRight v-if="locationOptions[farmIndex]?.barns?.length" class="flex-shrink-0 h-4 w-4 -mr-1" />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-stretch w-1/3 flex-shrink-0" role="group">
                            <div class="sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2">{{ $t('views.analysis.fields.locations.groups.barns') }}</div>
                            <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition duration-100 ease-in"
                                leave-from-class="opacity-0"
                                leave-to-class="opacity-0"
                            >
                                <div v-if="selectedActiveFarmIndex !== null" class="max-h-full overflow-y-auto">
                                    <div
                                        v-for="(barn, barnIndex) in barnOptions"
                                        :key="barn.barn_id"
                                        role="treeitem"
                                        :aria-label="barn.name"
                                        aria-level="2"
                                        :aria-expanded="selectedActiveBarnIndex === barnIndex"
                                        :aria-setsize="locationOptions[selectedActiveFarmIndex]?.barns.length"
                                        :aria-posinset="barnIndex + 1"
                                        class="flex items-center cursor-pointer"
                                        tabindex="0"
                                        @keydown.enter="selectActiveBarn(barnIndex)"
                                        @mouseover="selectActiveBarn(barnIndex)"
                                        @click="selectActiveBarn(barnIndex)"
                                    >
                                        <label
                                            class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors"
                                            :class="{
                                                'hover:bg-gray-100/70 focus:bg-gray-100/70': barnOptions[barnIndex]?._sections?.length && selectedActiveBarnIndex !== barnIndex,
                                                'bg-primary-300 bg-opacity-30': selectedActiveBarnIndex === barnIndex,
                                            }"
                                        >
                                            <CustomCheckbox
                                                :checked="barn.checked"
                                                :indeterminate="barn.indeterminate"
                                                disable-transition
                                                class="flex-shrink-0"
                                                @update:checked="
                                                    () => {
                                                        hasMadeSelection = true;
                                                        toggleBarn({ barn });
                                                    }
                                                "
                                            />
                                            <div class="flex grow gap-x-3 items-center justify-between max-w-full min-w-0">
                                                <span class="grow select-none truncate">{{ barn.name }}</span>
                                                <ChevronRight v-if="barnOptions[barnIndex]?._sections?.length || barnOptions[barnIndex]?.batches?.length" class="flex-shrink-0 h-4 w-4 -mr-1" />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="flex flex-col items-stretch w-1/3 flex-shrink-0" role="group">
                            <div class="sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2">
                                <transition
                                    enter-active-class="transition duration-100 ease-out"
                                    enter-from-class="opacity-0"
                                    enter-to-class="opacity-100"
                                    leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-0"
                                    leave-to-class="opacity-0"
                                    mode="out-in"
                                >
                                    <span :key="lastSectionText.key">{{ lastSectionText.text }}</span>
                                </transition>
                            </div>
                            <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition duration-100 ease-in"
                                leave-from-class="opacity-0"
                                leave-to-class="opacity-0"
                                mode="out-in"
                            >
                                <div v-if="selectedActiveFarmIndex !== null && selectedActiveBarnIndex !== null && barnOptions[selectedActiveBarnIndex]._sections?.length" class="max-h-full overflow-y-auto">
                                    <div
                                        v-for="section in locationOptions[selectedActiveFarmIndex]?.barns[selectedActiveBarnIndex]?._sections"
                                        :key="section.id"
                                        role="treeitem"
                                        :aria-label="section.name"
                                        aria-level="3"
                                        class="flex items-center cursor-pointer"
                                        tabindex="0"
                                    >
                                        <label class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer hover:bg-gray-100/70 focus:bg-gray-100/70 transition-colors">
                                            <CustomCheckbox
                                                :checked="section.checked"
                                                disable-transition
                                                class="flex-shrink-0"
                                                @update:checked="
                                                    () => {
                                                        hasMadeSelection = true;
                                                        toggleSectionOrBatch(section.id);
                                                    }
                                                "
                                            />
                                            <div class="grow select-none truncate max-w-full min-w-0">{{ section.name }}</div>
                                        </label>
                                    </div>
                                </div>
                                <div v-else-if="selectedActiveFarmIndex !== null && selectedActiveBarnIndex !== null && barnOptions[selectedActiveBarnIndex].batches?.length" class="max-h-full overflow-y-auto">
                                    <div
                                        v-for="batch in locationOptions[selectedActiveFarmIndex]?.barns[selectedActiveBarnIndex]?.batches"
                                        :key="batch.barn.id"
                                        role="treeitem"
                                        :aria-label="batch.name"
                                        aria-level="3"
                                        class="flex items-center cursor-pointer"
                                        tabindex="0"
                                    >
                                        <label class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer hover:bg-gray-100/70 focus:bg-gray-100/70 transition-colors">
                                            <CustomCheckbox
                                                :checked="batch.checked"
                                                disable-transition
                                                class="flex-shrink-0"
                                                @update:checked="
                                                    () => {
                                                        hasMadeSelection = true;
                                                        toggleSectionOrBatch(batch.id);
                                                    }
                                                "
                                            />
                                            <div class="flex items-center gap-x-1.5 grow">
                                                <div>
                                                    {{ batch.name }}
                                                </div>
                                                <div class="text-gray-500 text-sm whitespace-nowrap">
                                                    ({{ $d(new Date(batch.start_date), 'short') }}{{ batch.end_date ? ` - ${$d(new Date(batch.end_date), 'short')}` : '' }})
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
            <div class="flex justify-end">
                <CustomButton class="w-auto min-w-40 px-5" :disabled="!selectedLocationIds.length || isLoading" @click="generateDashboard">{{ $t('views.analysis.buttons.generate') }}</CustomButton>
            </div>
        </div>
        <div class="default-tabs">
            <Tabs teleport="#analysis-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.analysis.tabTitles.company')" :default="true">
                            <CompanyTab
                                :selected-analysis-type="fetchState.selectedAnalysisType"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :is-weaners="fetchState.isWeaners"
                                :period="fetchState.period"
                                :include-complete-batch="fetchState.includeCompleteBatch"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                    <div class="flex pr-4">
                        <Tab :name="$t('views.analysis.tabTitles.farm')">
                            <FarmTab
                                :selected-analysis-type="fetchState.selectedAnalysisType"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :is-weaners="fetchState.isWeaners"
                                :period="fetchState.period"
                                :include-complete-batch="fetchState.includeCompleteBatch"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                    <div class="flex pr-4">
                        <Tab :name="$t('views.analysis.tabTitles.barn')">
                            <BarnTab
                                :selected-analysis-type="fetchState.selectedAnalysisType"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :is-weaners="fetchState.isWeaners"
                                :period="fetchState.period"
                                :include-complete-batch="fetchState.includeCompleteBatch"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                    <div class="flex pr-4" :class="{ hidden: !hasSectionsModuleEnabled }">
                        <Tab :name="$t('views.analysis.tabTitles.section')">
                            <SectionTab
                                :selected-analysis-type="fetchState.selectedAnalysisType"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :is-weaners="fetchState.isWeaners"
                                :period="fetchState.period"
                                :include-complete-batch="fetchState.includeCompleteBatch"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.analysis.tabTitles.batch')">
                            <BatchTab
                                :selected-analysis-type="fetchState.selectedAnalysisType"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :is-weaners="fetchState.isWeaners"
                                :period="fetchState.period"
                                :include-complete-batch="fetchState.includeCompleteBatch"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                </nav>
            </Tabs>
            <div id="analysis-tabs-target" class="mt-6" />
        </div>
    </DashboardContentLayout>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { ChevronRight, ChevronDown } from 'lucide-vue-next';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { useIsFetching, useQueryClient } from '@tanstack/vue-query';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import { store } from '@/plugins/store';
import { MutationType } from '@/plugins/store/mutations';
import { ActionType } from '@/plugins/store/actions';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { Barn, DirectoryBatch, Farm, Module, Section } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import CompanyTab from './CompanyTab.vue';
import FarmTab from './FarmTab.vue';
import BarnTab from './BarnTab.vue';
import SectionTab from './SectionTab.vue';
import BatchTab from './BatchTab.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { queryKeys } from '@/plugins/store/actions/queries/analysis/dashboards';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { IconSource } from '@/types/IconSource';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

store.dispatch(ActionType.GetAnalysisTypes);
useRefetchDataOnDashboardStateChanged(ActionType.GetBatches, { watchSites: false, watchFarmsInsteadOfSites: false, watchFormSubmissions: false });

const hasSectionsModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.Sections));

const isFetching = useIsFetching({ queryKey: queryKeys.getDashboardRoot });
const isLoading = computed(() => isFetching.value > 0);

const pigTypeOptions = computed(() => [
    {
        value: '0',
        label: i18n.global.t('views.analysis.fields.pigType.options.all'),
    },
    {
        value: '1',
        label: i18n.global.t('views.analysis.fields.pigType.options.weaners'),
    },
    {
        value: '2',
        label: i18n.global.t('views.analysis.fields.pigType.options.slaughterPigs'),
    },
]);
const pigType = ref(pigTypeOptions.value[0].value);
const includeCompleteBatch = ref(false);
const analysisTypes = computed(() => store.state.analysisTypes);
const formattedAnalysisTypes = computed(() => analysisTypes.value.map((t) => ({ value: t.id.toString(), label: t.name })));
const selectedAnalysisType = computed({
    get() {
        return store.state.analysisDashboardState.selectedAnalysisTypeId?.toString() ?? undefined;
    },
    set(value) {
        store.commit(MutationType.SetAnalysisDashboardState, {
            ...store.state.analysisDashboardState,
            selectedAnalysisTypeId: value ? parseInt(value) : null,
        });
    },
});
const fetchState = ref<{ selectedAnalysisType: string | undefined; selectedLocationIds: number[]; isWeaners?: boolean; period: string; includeCompleteBatch: boolean; fetchIndex: number }>({
    selectedAnalysisType: undefined,
    selectedLocationIds: [],
    isWeaners: undefined,
    period: '',
    includeCompleteBatch: false,
    // Used to trigger a refetch of the dashboard when generate is clicked
    fetchIndex: 0,
});
const isLocationPickerOpen = ref(false);
interface LocationOptionSection extends Section {
    checked: boolean;
}

interface LocationOptionBatch extends DirectoryBatch {
    checked: boolean;
}

interface LocationOptionBarn extends Barn {
    checked: boolean;
    indeterminate: boolean;
    _sections: LocationOptionSection[] | null;
    batches: LocationOptionBatch[] | null;
}

interface LocationOption extends Farm {
    barns: LocationOptionBarn[];
    checked: boolean;
    indeterminate: boolean;
}

const stateBatches = computed(() => store.state.batches);

const locationOptions = computed<LocationOption[]>(() => {
    if (!store.state.farms?.length) {
        return [];
    }

    return store.state.farms
        .map((f) => {
            const barns = store.state.barns?.filter((b) => b.farm_id === f.farm_id && (pigType.value === '0' || b.is_weaners === (pigType.value === '1')));
            const populatedBarns = barns?.length
                ? barns.map((b) => {
                      const sections = store.state.sections?.filter((s) => s.barn_id === b.barn_id).map((s) => ({ ...s, checked: selectedLocationIds.value.includes(s.id) }));
                      const batches = stateBatches.value.filter((batch) => batch.barn.id === b.barn_id).map((batch) => ({ ...batch, checked: selectedLocationIds.value.includes(batch.id) }));
                      const populatedSections = sections?.length ? sections : null;
                      const populatedBatches = batches?.length ? batches : null;

                      const checked =
                          (selectedLocationIds.value.includes(b.barn_id) ||
                              (b.is_batch_production ? populatedBatches?.every((batch) => batch.checked) : populatedSections?.every((section) => section.checked))) ??
                          false;

                      return {
                          ...b,
                          _sections: populatedSections,
                          batches: populatedBatches,
                          checked,
                          indeterminate: (!checked && (b.is_batch_production ? populatedBatches?.some((batch) => batch.checked) : populatedSections?.some((section) => section.checked))) ?? false,
                      };
                  })
                : null;

            const checked = populatedBarns?.every((b) => b.checked) ?? false;

            return populatedBarns
                ? {
                      ...f,
                      barns: populatedBarns,
                      checked,
                      indeterminate: (!checked && populatedBarns?.some((b) => b.checked || b.indeterminate)) ?? false,
                  }
                : null;
        })
        .filter(Boolean) as LocationOption[];
});
const barnOptions = computed(() => {
    if (selectedActiveFarmIndex.value === null) {
        return [];
    }

    return locationOptions.value[selectedActiveFarmIndex.value].barns;
});
const selectedActiveFarmIndex = ref<number | null>(null);
const selectedActiveBarnIndex = ref<number | null>(null);
const selectedLocationIds = ref<number[]>([]);

const counts = computed(() => {
    const state = {
        farms: 0,
        barns: 0,
        sections: 0,
        batches: 0,
    };

    for (const farm of locationOptions.value) {
        if (farm.checked || farm.indeterminate) {
            state.farms++;
        }

        for (const barn of farm.barns) {
            if (barn.checked || barn.indeterminate) {
                state.barns++;
            }

            if (barn.is_batch_production && barn.batches) {
                for (const batch of barn.batches) {
                    if (batch.checked) {
                        state.batches++;
                    }
                }
            } else if (barn._sections) {
                for (const section of barn._sections) {
                    if (section.checked) {
                        state.sections++;
                    }
                }
            }
        }
    }

    return state;
});
const countText = computed(() => {
    const { farms, barns, sections, batches } = counts.value;

    const texts = [];

    if (farms) {
        texts.push(`${farms} ${farms === 1 ? i18n.global.t('views.analysis.fields.locations.texts.farm') : i18n.global.t('views.analysis.fields.locations.texts.farms')}`);
    }

    if (barns) {
        texts.push(`${barns} ${barns === 1 ? i18n.global.t('views.analysis.fields.locations.texts.barn') : i18n.global.t('views.analysis.fields.locations.texts.barns')}`);
    }

    if (sections) {
        texts.push(`${sections} ${sections === 1 ? i18n.global.t('views.analysis.fields.locations.texts.section') : i18n.global.t('views.analysis.fields.locations.texts.sections')}`);
    }

    if (batches) {
        texts.push(`${batches} ${batches === 1 ? i18n.global.t('views.analysis.fields.locations.texts.batch') : i18n.global.t('views.analysis.fields.locations.texts.batches')}`);
    }

    return texts.length > 0 ? texts.join(', ') : i18n.global.t('views.analysis.fields.locations.texts.noSelections');
});

const lastSectionText = computed(() => {
    const batchText = {
        text: i18n.global.t('views.analysis.fields.locations.groups.batches'),
        key: 'batches',
    };
    const sectionText = {
        text: i18n.global.t('views.analysis.fields.locations.groups.sections'),
        key: 'sections',
    };

    if (selectedActiveBarnIndex.value === null) {
        return hasSectionsModuleEnabled.value ? sectionText : batchText;
    }

    const barn = locationOptions.value[selectedActiveFarmIndex.value!].barns[selectedActiveBarnIndex.value];

    if (barn.is_batch_production) {
        return batchText;
    }

    if (hasSectionsModuleEnabled.value) {
        return sectionText;
    }

    return batchText;
});

const hasMadeSelection = ref(false);

const sitesAreLoadedWatcherStop = watch(
    () => store.state.sitesAreLoaded,
    async (sitesAreLoaded) => {
        if (sitesAreLoaded) {
            await nextTick();

            for (const farm of locationOptions.value) {
                toggleFarm({ farm, checked: false });
            }

            sitesAreLoadedWatcherStop();
        }
    },
    { immediate: true }
);

const batchesWatcherStop = watch(
    () => stateBatches.value,
    async () => {
        if (hasMadeSelection.value) {
            batchesWatcherStop();

            return;
        }

        await nextTick();

        for (const farm of locationOptions.value) {
            toggleFarm({ farm, checked: false });
        }
    },
    { immediate: true }
);

function togglePigType(value: string) {
    pigType.value = value;

    selectedLocationIds.value = [];
    selectedActiveFarmIndex.value = null;
    selectedActiveBarnIndex.value = null;

    for (const farm of locationOptions.value) {
        toggleFarm({ farm, checked: false, isWeaners: value === '0' ? undefined : value === '1' });
    }
}

function selectActiveFarm(farmIndex: number) {
    selectedActiveFarmIndex.value = farmIndex;
    selectedActiveBarnIndex.value = null;
}

function selectActiveBarn(barnIndex: number) {
    selectedActiveBarnIndex.value = barnIndex;
}

function toggleFarm({ farm, checked, isWeaners }: { farm: LocationOption; checked?: boolean; isWeaners?: boolean }) {
    let barns = farm.barns;

    if (isWeaners !== undefined) {
        barns = barns.filter((b) => b.is_weaners === isWeaners);
    }

    for (const barn of barns) {
        toggleBarn({ barn, checked: checked ?? farm.checked });
    }
}

function toggleBarn({ barn, checked }: { barn: LocationOptionBarn; checked?: boolean }) {
    const isChecked = checked !== undefined ? checked : barn.checked;

    if (isChecked) {
        const idsToRemove = !barn._sections && !barn.batches ? [barn.barn_id] : [];

        if (barn.is_batch_production) {
            idsToRemove.push(...(barn.batches?.map((b) => b.id) || []));
        } else {
            idsToRemove.push(...(barn._sections?.map((s) => s.id) || []));
        }

        selectedLocationIds.value = selectedLocationIds.value.filter((id) => !idsToRemove.includes(id));
    } else if (!barn._sections && !barn.batches) {
        if (!selectedLocationIds.value.includes(barn.barn_id)) {
            selectedLocationIds.value.push(barn.barn_id);
        }
    } else if (barn.is_batch_production) {
        const locationIdsToAdd = barn.batches?.map((b) => b.id) || [];

        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, ...locationIdsToAdd])];
    } else {
        const locationIdsToAdd = barn._sections?.map((s) => s.id) || [];

        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, ...locationIdsToAdd])];
    }
}

function toggleSectionOrBatch(sectionOrBatchId: number) {
    if (selectedLocationIds.value.includes(sectionOrBatchId)) {
        selectedLocationIds.value = selectedLocationIds.value.filter((id) => id !== sectionOrBatchId);
    } else {
        selectedLocationIds.value.push(sectionOrBatchId);
    }
}

function getSelectedLocationIds() {
    const locationIds: number[] = [];

    for (const farm of locationOptions.value) {
        for (const barn of farm.barns) {
            const checkedSections = barn._sections?.filter((s) => s.checked) || [];
            const checkedBatches = barn.batches?.filter((b) => b.checked) || [];

            if (checkedSections.length) {
                locationIds.push(...checkedSections.map((s) => s.id));
            }

            if (checkedBatches.length) {
                locationIds.push(...checkedBatches.map((b) => b.id));
            }

            if (checkedSections.length || checkedBatches.length || barn.checked) {
                locationIds.push(farm.farm_id);
                locationIds.push(barn.barn_id);
            }
        }
    }

    return locationIds;
}

function generateDashboard() {
    fetchState.value = {
        selectedAnalysisType: selectedAnalysisType.value,
        selectedLocationIds: getSelectedLocationIds(),
        isWeaners: pigType.value === '0' ? undefined : pigType.value === '1',
        period: store.getters.getSelectedPeriod,
        includeCompleteBatch: includeCompleteBatch.value,
        fetchIndex: fetchState.value.fetchIndex + 1,
    };
}

watch(analysisTypes, (newAnalysisTypes) => {
    if (!newAnalysisTypes?.length) {
        return;
    }

    store.commit(MutationType.SetAnalysisDashboardState, {
        ...store.state.analysisDashboardState,
        selectedAnalysisTypeId: newAnalysisTypes[0].id,
    });
});

const queryClient = useQueryClient();

onMounted(() => {
    queryClient.invalidateQueries({ queryKey: queryKeys.getDashboardRoot });
});
</script>
