import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, Transition as _Transition, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex items-center gap-x-1 leading-normal" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { class: "text-gray-500" }
const _hoisted_6 = { class: "w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left" }
const _hoisted_7 = { class: "flex items-center gap-x-1 leading-normal" }
const _hoisted_8 = { class: "font-medium" }
const _hoisted_9 = { class: "text-gray-500" }
const _hoisted_10 = { class: "flex-shrink-0 flex justify-between gap-x-4 px-3 py-4" }
const _hoisted_11 = { class: "flex items-center gap-x-1 pr-2" }
const _hoisted_12 = { class: "flex cursor-pointer items-center gap-x-1.5" }
const _hoisted_13 = {
  type: "button",
  class: "flex items-center justify-center p-1 rounded-full transition-colors hover:bg-gray-100"
}
const _hoisted_14 = { class: "sr-only" }
const _hoisted_15 = {
  role: "tree",
  "aria-orientation": "horizontal",
  class: "flex flex-grow divide-x max-h-full overflow-hidden"
}
const _hoisted_16 = {
  class: "flex flex-col items-stretch w-1/3 flex-shrink-0",
  role: "group"
}
const _hoisted_17 = { class: "sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2" }
const _hoisted_18 = { class: "max-h-full overflow-y-auto" }
const _hoisted_19 = ["aria-label", "aria-expanded", "aria-setsize", "aria-posinset", "onKeydown", "onMouseover", "onClick"]
const _hoisted_20 = { class: "flex grow gap-x-3 items-center justify-between max-w-full min-w-0" }
const _hoisted_21 = { class: "grow select-none truncate" }
const _hoisted_22 = {
  class: "flex flex-col items-stretch w-1/3 flex-shrink-0",
  role: "group"
}
const _hoisted_23 = { class: "sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2" }
const _hoisted_24 = {
  key: 0,
  class: "max-h-full overflow-y-auto"
}
const _hoisted_25 = ["aria-label", "aria-expanded", "aria-setsize", "aria-posinset", "onKeydown", "onMouseover", "onClick"]
const _hoisted_26 = { class: "flex grow gap-x-3 items-center justify-between max-w-full min-w-0" }
const _hoisted_27 = { class: "grow select-none truncate" }
const _hoisted_28 = {
  class: "flex flex-col items-stretch w-1/3 flex-shrink-0",
  role: "group"
}
const _hoisted_29 = { class: "sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2" }
const _hoisted_30 = {
  key: 0,
  class: "max-h-full overflow-y-auto"
}
const _hoisted_31 = ["aria-label"]
const _hoisted_32 = { class: "flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer hover:bg-gray-100/70 focus:bg-gray-100/70 transition-colors" }
const _hoisted_33 = { class: "grow select-none truncate max-w-full min-w-0" }
const _hoisted_34 = {
  key: 1,
  class: "max-h-full overflow-y-auto"
}
const _hoisted_35 = ["aria-label"]
const _hoisted_36 = { class: "flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer hover:bg-gray-100/70 focus:bg-gray-100/70 transition-colors" }
const _hoisted_37 = { class: "flex items-center gap-x-1.5 grow" }
const _hoisted_38 = { class: "text-gray-500 text-sm whitespace-nowrap" }
const _hoisted_39 = { class: "flex justify-end" }
const _hoisted_40 = { class: "default-tabs" }
const _hoisted_41 = { class: "space-x-8 flex border-b border-black border-opacity-10" }
const _hoisted_42 = { class: "flex pr-4" }
const _hoisted_43 = { class: "flex pr-4" }
const _hoisted_44 = { class: "flex pr-4" }
const _hoisted_45 = { class: "flex" }

import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { ChevronRight, ChevronDown } from 'lucide-vue-next';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { useIsFetching, useQueryClient } from '@tanstack/vue-query';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import { store } from '@/plugins/store';
import { MutationType } from '@/plugins/store/mutations';
import { ActionType } from '@/plugins/store/actions';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { Barn, DirectoryBatch, Farm, Module, Section } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import CompanyTab from './CompanyTab.vue';
import FarmTab from './FarmTab.vue';
import BarnTab from './BarnTab.vue';
import SectionTab from './SectionTab.vue';
import BatchTab from './BatchTab.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { queryKeys } from '@/plugins/store/actions/queries/analysis/dashboards';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { IconSource } from '@/types/IconSource';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

interface LocationOptionSection extends Section {
    checked: boolean;
}

interface LocationOptionBatch extends DirectoryBatch {
    checked: boolean;
}

interface LocationOptionBarn extends Barn {
    checked: boolean;
    indeterminate: boolean;
    _sections: LocationOptionSection[] | null;
    batches: LocationOptionBatch[] | null;
}

interface LocationOption extends Farm {
    barns: LocationOptionBarn[];
    checked: boolean;
    indeterminate: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

store.dispatch(ActionType.GetAnalysisTypes);
useRefetchDataOnDashboardStateChanged(ActionType.GetBatches, { watchSites: false, watchFarmsInsteadOfSites: false, watchFormSubmissions: false });

const hasSectionsModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.Sections));

const isFetching = useIsFetching({ queryKey: queryKeys.getDashboardRoot });
const isLoading = computed(() => isFetching.value > 0);

const pigTypeOptions = computed(() => [
    {
        value: '0',
        label: i18n.global.t('views.analysis.fields.pigType.options.all'),
    },
    {
        value: '1',
        label: i18n.global.t('views.analysis.fields.pigType.options.weaners'),
    },
    {
        value: '2',
        label: i18n.global.t('views.analysis.fields.pigType.options.slaughterPigs'),
    },
]);
const pigType = ref(pigTypeOptions.value[0].value);
const includeCompleteBatch = ref(false);
const analysisTypes = computed(() => store.state.analysisTypes);
const formattedAnalysisTypes = computed(() => analysisTypes.value.map((t) => ({ value: t.id.toString(), label: t.name })));
const selectedAnalysisType = computed({
    get() {
        return store.state.analysisDashboardState.selectedAnalysisTypeId?.toString() ?? undefined;
    },
    set(value) {
        store.commit(MutationType.SetAnalysisDashboardState, {
            ...store.state.analysisDashboardState,
            selectedAnalysisTypeId: value ? parseInt(value) : null,
        });
    },
});
const fetchState = ref<{ selectedAnalysisType: string | undefined; selectedLocationIds: number[]; isWeaners?: boolean; period: string; includeCompleteBatch: boolean; fetchIndex: number }>({
    selectedAnalysisType: undefined,
    selectedLocationIds: [],
    isWeaners: undefined,
    period: '',
    includeCompleteBatch: false,
    // Used to trigger a refetch of the dashboard when generate is clicked
    fetchIndex: 0,
});
const isLocationPickerOpen = ref(false);
const stateBatches = computed(() => store.state.batches);

const locationOptions = computed<LocationOption[]>(() => {
    if (!store.state.farms?.length) {
        return [];
    }

    return store.state.farms
        .map((f) => {
            const barns = store.state.barns?.filter((b) => b.farm_id === f.farm_id && (pigType.value === '0' || b.is_weaners === (pigType.value === '1')));
            const populatedBarns = barns?.length
                ? barns.map((b) => {
                      const sections = store.state.sections?.filter((s) => s.barn_id === b.barn_id).map((s) => ({ ...s, checked: selectedLocationIds.value.includes(s.id) }));
                      const batches = stateBatches.value.filter((batch) => batch.barn.id === b.barn_id).map((batch) => ({ ...batch, checked: selectedLocationIds.value.includes(batch.id) }));
                      const populatedSections = sections?.length ? sections : null;
                      const populatedBatches = batches?.length ? batches : null;

                      const checked =
                          (selectedLocationIds.value.includes(b.barn_id) ||
                              (b.is_batch_production ? populatedBatches?.every((batch) => batch.checked) : populatedSections?.every((section) => section.checked))) ??
                          false;

                      return {
                          ...b,
                          _sections: populatedSections,
                          batches: populatedBatches,
                          checked,
                          indeterminate: (!checked && (b.is_batch_production ? populatedBatches?.some((batch) => batch.checked) : populatedSections?.some((section) => section.checked))) ?? false,
                      };
                  })
                : null;

            const checked = populatedBarns?.every((b) => b.checked) ?? false;

            return populatedBarns
                ? {
                      ...f,
                      barns: populatedBarns,
                      checked,
                      indeterminate: (!checked && populatedBarns?.some((b) => b.checked || b.indeterminate)) ?? false,
                  }
                : null;
        })
        .filter(Boolean) as LocationOption[];
});
const barnOptions = computed(() => {
    if (selectedActiveFarmIndex.value === null) {
        return [];
    }

    return locationOptions.value[selectedActiveFarmIndex.value].barns;
});
const selectedActiveFarmIndex = ref<number | null>(null);
const selectedActiveBarnIndex = ref<number | null>(null);
const selectedLocationIds = ref<number[]>([]);

const counts = computed(() => {
    const state = {
        farms: 0,
        barns: 0,
        sections: 0,
        batches: 0,
    };

    for (const farm of locationOptions.value) {
        if (farm.checked || farm.indeterminate) {
            state.farms++;
        }

        for (const barn of farm.barns) {
            if (barn.checked || barn.indeterminate) {
                state.barns++;
            }

            if (barn.is_batch_production && barn.batches) {
                for (const batch of barn.batches) {
                    if (batch.checked) {
                        state.batches++;
                    }
                }
            } else if (barn._sections) {
                for (const section of barn._sections) {
                    if (section.checked) {
                        state.sections++;
                    }
                }
            }
        }
    }

    return state;
});
const countText = computed(() => {
    const { farms, barns, sections, batches } = counts.value;

    const texts = [];

    if (farms) {
        texts.push(`${farms} ${farms === 1 ? i18n.global.t('views.analysis.fields.locations.texts.farm') : i18n.global.t('views.analysis.fields.locations.texts.farms')}`);
    }

    if (barns) {
        texts.push(`${barns} ${barns === 1 ? i18n.global.t('views.analysis.fields.locations.texts.barn') : i18n.global.t('views.analysis.fields.locations.texts.barns')}`);
    }

    if (sections) {
        texts.push(`${sections} ${sections === 1 ? i18n.global.t('views.analysis.fields.locations.texts.section') : i18n.global.t('views.analysis.fields.locations.texts.sections')}`);
    }

    if (batches) {
        texts.push(`${batches} ${batches === 1 ? i18n.global.t('views.analysis.fields.locations.texts.batch') : i18n.global.t('views.analysis.fields.locations.texts.batches')}`);
    }

    return texts.length > 0 ? texts.join(', ') : i18n.global.t('views.analysis.fields.locations.texts.noSelections');
});

const lastSectionText = computed(() => {
    const batchText = {
        text: i18n.global.t('views.analysis.fields.locations.groups.batches'),
        key: 'batches',
    };
    const sectionText = {
        text: i18n.global.t('views.analysis.fields.locations.groups.sections'),
        key: 'sections',
    };

    if (selectedActiveBarnIndex.value === null) {
        return hasSectionsModuleEnabled.value ? sectionText : batchText;
    }

    const barn = locationOptions.value[selectedActiveFarmIndex.value!].barns[selectedActiveBarnIndex.value];

    if (barn.is_batch_production) {
        return batchText;
    }

    if (hasSectionsModuleEnabled.value) {
        return sectionText;
    }

    return batchText;
});

const hasMadeSelection = ref(false);

const sitesAreLoadedWatcherStop = watch(
    () => store.state.sitesAreLoaded,
    async (sitesAreLoaded) => {
        if (sitesAreLoaded) {
            await nextTick();

            for (const farm of locationOptions.value) {
                toggleFarm({ farm, checked: false });
            }

            sitesAreLoadedWatcherStop();
        }
    },
    { immediate: true }
);

const batchesWatcherStop = watch(
    () => stateBatches.value,
    async () => {
        if (hasMadeSelection.value) {
            batchesWatcherStop();

            return;
        }

        await nextTick();

        for (const farm of locationOptions.value) {
            toggleFarm({ farm, checked: false });
        }
    },
    { immediate: true }
);

function togglePigType(value: string) {
    pigType.value = value;

    selectedLocationIds.value = [];
    selectedActiveFarmIndex.value = null;
    selectedActiveBarnIndex.value = null;

    for (const farm of locationOptions.value) {
        toggleFarm({ farm, checked: false, isWeaners: value === '0' ? undefined : value === '1' });
    }
}

function selectActiveFarm(farmIndex: number) {
    selectedActiveFarmIndex.value = farmIndex;
    selectedActiveBarnIndex.value = null;
}

function selectActiveBarn(barnIndex: number) {
    selectedActiveBarnIndex.value = barnIndex;
}

function toggleFarm({ farm, checked, isWeaners }: { farm: LocationOption; checked?: boolean; isWeaners?: boolean }) {
    let barns = farm.barns;

    if (isWeaners !== undefined) {
        barns = barns.filter((b) => b.is_weaners === isWeaners);
    }

    for (const barn of barns) {
        toggleBarn({ barn, checked: checked ?? farm.checked });
    }
}

function toggleBarn({ barn, checked }: { barn: LocationOptionBarn; checked?: boolean }) {
    const isChecked = checked !== undefined ? checked : barn.checked;

    if (isChecked) {
        const idsToRemove = !barn._sections && !barn.batches ? [barn.barn_id] : [];

        if (barn.is_batch_production) {
            idsToRemove.push(...(barn.batches?.map((b) => b.id) || []));
        } else {
            idsToRemove.push(...(barn._sections?.map((s) => s.id) || []));
        }

        selectedLocationIds.value = selectedLocationIds.value.filter((id) => !idsToRemove.includes(id));
    } else if (!barn._sections && !barn.batches) {
        if (!selectedLocationIds.value.includes(barn.barn_id)) {
            selectedLocationIds.value.push(barn.barn_id);
        }
    } else if (barn.is_batch_production) {
        const locationIdsToAdd = barn.batches?.map((b) => b.id) || [];

        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, ...locationIdsToAdd])];
    } else {
        const locationIdsToAdd = barn._sections?.map((s) => s.id) || [];

        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, ...locationIdsToAdd])];
    }
}

function toggleSectionOrBatch(sectionOrBatchId: number) {
    if (selectedLocationIds.value.includes(sectionOrBatchId)) {
        selectedLocationIds.value = selectedLocationIds.value.filter((id) => id !== sectionOrBatchId);
    } else {
        selectedLocationIds.value.push(sectionOrBatchId);
    }
}

function getSelectedLocationIds() {
    const locationIds: number[] = [];

    for (const farm of locationOptions.value) {
        for (const barn of farm.barns) {
            const checkedSections = barn._sections?.filter((s) => s.checked) || [];
            const checkedBatches = barn.batches?.filter((b) => b.checked) || [];

            if (checkedSections.length) {
                locationIds.push(...checkedSections.map((s) => s.id));
            }

            if (checkedBatches.length) {
                locationIds.push(...checkedBatches.map((b) => b.id));
            }

            if (checkedSections.length || checkedBatches.length || barn.checked) {
                locationIds.push(farm.farm_id);
                locationIds.push(barn.barn_id);
            }
        }
    }

    return locationIds;
}

function generateDashboard() {
    fetchState.value = {
        selectedAnalysisType: selectedAnalysisType.value,
        selectedLocationIds: getSelectedLocationIds(),
        isWeaners: pigType.value === '0' ? undefined : pigType.value === '1',
        period: store.getters.getSelectedPeriod,
        includeCompleteBatch: includeCompleteBatch.value,
        fetchIndex: fetchState.value.fetchIndex + 1,
    };
}

watch(analysisTypes, (newAnalysisTypes) => {
    if (!newAnalysisTypes?.length) {
        return;
    }

    store.commit(MutationType.SetAnalysisDashboardState, {
        ...store.state.analysisDashboardState,
        selectedAnalysisTypeId: newAnalysisTypes[0].id,
    });
});

const queryClient = useQueryClient();

onMounted(() => {
    queryClient.invalidateQueries({ queryKey: queryKeys.getDashboardRoot });
});

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.analysis.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(Listbox), {
            modelValue: selectedAnalysisType.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedAnalysisType).value = $event))
          }, {
            default: _withCtx(({ open }) => [
              _createVNode(_unref(ListboxButton), { class: "w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('views.analysis.fields.category.label')), 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(formattedAnalysisTypes.value.find((t) => t.value === selectedAnalysisType.value)?.label || _ctx.$t('views.analysis.fields.category.texts.noSelection')), 1)
                  ]),
                  _createVNode(_unref(ChevronDown), {
                    class: _normalizeClass(["flex-shrink-0 h-5 w-5 transition-transform", { 'rotate-180': open }]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_Transition, {
                "enter-active-class": "transition duration-100 ease-out",
                "enter-from-class": "transform scale-95 opacity-0",
                "enter-to-class": "transform scale-100 opacity-100",
                "leave-active-class": "transition duration-100 ease-out",
                "leave-from-class": "transform scale-100 opacity-100",
                "leave-to-class": "transform scale-95 opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ListboxOptions), { class: "absolute z-20 mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedAnalysisTypes.value, (type) => {
                        return (_openBlock(), _createBlock(_unref(ListboxOption), {
                          key: type.value,
                          value: type.value
                        }, {
                          default: _withCtx(({ active, selected }) => [
                            _createElementVNode("li", {
                              class: _normalizeClass(["py-2 px-3 h-full truncate block w-full text-left cursor-pointer", {
                                        'bg-primary-300 bg-opacity-40': selected,
                                        'hover:bg-gray-100': !selected,
                                        'bg-gray-100': active && !selected,
                                    }])
                            }, _toDisplayString(type.label), 3)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createVNode(_unref(Popover), {
          open: isLocationPickerOpen.value,
          "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((isLocationPickerOpen).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(PopoverTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('views.analysis.fields.locations.label')), 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(countText.value), 1)
                  ]),
                  _createVNode(_unref(ChevronDown), {
                    class: _normalizeClass(["flex-shrink-0 h-5 w-5 transition-transform", { 'rotate-180': isLocationPickerOpen.value }]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(PopoverContent), {
              "trigger-width": "",
              align: "start",
              class: "flex flex-col p-0 h-[516px] overflow-hidden"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(RadioGroup), {
                    "model-value": pigType.value,
                    "onUpdate:modelValue": togglePigType
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pigTypeOptions.value, (option) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: option.value,
                          class: "flex items-center cursor-pointer"
                        }, [
                          _createVNode(_unref(RadioGroupItem), {
                            id: `option-id-${option.value}`,
                            value: option.value
                          }, null, 8, ["id", "value"]),
                          _createVNode(_unref(Label), {
                            for: `option-id-${option.value}`,
                            class: "font-normal cursor-pointer pl-2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["for"])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["model-value"]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, [
                      _createVNode(CustomCheckbox, {
                        checked: includeCompleteBatch.value,
                        "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((includeCompleteBatch).value = $event))
                      }, null, 8, ["checked"]),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.analysis.fields.includeAllBatchData.label')), 1)
                    ]),
                    _createVNode(_unref(TooltipProvider), { "delay-duration": 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Tooltip), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                              default: _withCtx(() => [
                                _createElementVNode("button", _hoisted_13, [
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('views.analysis.fields.includeAllBatchData.label')), 1),
                                  _createVNode(_component_Icon, {
                                    class: "h-6 w-auto",
                                    src: _unref(IconSource).HelpCircleIcon
                                  }, null, 8, ["src"])
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(TooltipContent), { "collision-padding": 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t('views.analysis.fields.includeAllBatchData.tooltip')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0 h-px w-full bg-gray-200" }, null, -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('views.analysis.fields.locations.groups.farms')), 1),
                    _createElementVNode("div", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationOptions.value, (farm, farmIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: farm.farm_id,
                          class: "flex items-center cursor-pointer",
                          role: "treeitem",
                          "aria-label": farm.name,
                          "aria-level": "1",
                          "aria-expanded": selectedActiveFarmIndex.value === farmIndex,
                          "aria-setsize": locationOptions.value.length,
                          "aria-posinset": farmIndex + 1,
                          tabindex: "0",
                          onKeydown: _withKeys(($event: any) => (selectActiveFarm(farmIndex)), ["enter"]),
                          onMouseover: ($event: any) => (selectActiveFarm(farmIndex)),
                          onClick: ($event: any) => (selectActiveFarm(farmIndex))
                        }, [
                          _createElementVNode("label", {
                            class: _normalizeClass(["flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors", {
                                            'hover:bg-gray-100/70 focus:bg-gray-100/70': locationOptions.value[farmIndex]?.barns?.length && selectedActiveFarmIndex.value !== farmIndex,
                                            'bg-primary-300 bg-opacity-30': selectedActiveFarmIndex.value === farmIndex,
                                        }])
                          }, [
                            _createVNode(CustomCheckbox, {
                              checked: farm.checked,
                              indeterminate: farm.indeterminate,
                              "disable-transition": "",
                              class: "flex-shrink-0",
                              "onUpdate:checked": 
                                                () => {
                                                    hasMadeSelection.value = true;
                                                    toggleFarm({ farm });
                                                }
                                            
                            }, null, 8, ["checked", "indeterminate", "onUpdate:checked"]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("span", _hoisted_21, _toDisplayString(farm.name), 1),
                              (locationOptions.value[farmIndex]?.barns?.length)
                                ? (_openBlock(), _createBlock(_unref(ChevronRight), {
                                    key: 0,
                                    class: "flex-shrink-0 h-4 w-4 -mr-1"
                                  }))
                                : _createCommentVNode("", true)
                            ])
                          ], 2)
                        ], 40, _hoisted_19))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('views.analysis.fields.locations.groups.barns')), 1),
                    _createVNode(_Transition, {
                      "enter-active-class": "transition duration-100 ease-out",
                      "enter-from-class": "opacity-0",
                      "enter-to-class": "opacity-100",
                      "leave-active-class": "transition duration-100 ease-in",
                      "leave-from-class": "opacity-0",
                      "leave-to-class": "opacity-0"
                    }, {
                      default: _withCtx(() => [
                        (selectedActiveFarmIndex.value !== null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(barnOptions.value, (barn, barnIndex) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: barn.barn_id,
                                  role: "treeitem",
                                  "aria-label": barn.name,
                                  "aria-level": "2",
                                  "aria-expanded": selectedActiveBarnIndex.value === barnIndex,
                                  "aria-setsize": locationOptions.value[selectedActiveFarmIndex.value]?.barns.length,
                                  "aria-posinset": barnIndex + 1,
                                  class: "flex items-center cursor-pointer",
                                  tabindex: "0",
                                  onKeydown: _withKeys(($event: any) => (selectActiveBarn(barnIndex)), ["enter"]),
                                  onMouseover: ($event: any) => (selectActiveBarn(barnIndex)),
                                  onClick: ($event: any) => (selectActiveBarn(barnIndex))
                                }, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors", {
                                                'hover:bg-gray-100/70 focus:bg-gray-100/70': barnOptions.value[barnIndex]?._sections?.length && selectedActiveBarnIndex.value !== barnIndex,
                                                'bg-primary-300 bg-opacity-30': selectedActiveBarnIndex.value === barnIndex,
                                            }])
                                  }, [
                                    _createVNode(CustomCheckbox, {
                                      checked: barn.checked,
                                      indeterminate: barn.indeterminate,
                                      "disable-transition": "",
                                      class: "flex-shrink-0",
                                      "onUpdate:checked": 
                                                    () => {
                                                        hasMadeSelection.value = true;
                                                        toggleBarn({ barn });
                                                    }
                                                
                                    }, null, 8, ["checked", "indeterminate", "onUpdate:checked"]),
                                    _createElementVNode("div", _hoisted_26, [
                                      _createElementVNode("span", _hoisted_27, _toDisplayString(barn.name), 1),
                                      (barnOptions.value[barnIndex]?._sections?.length || barnOptions.value[barnIndex]?.batches?.length)
                                        ? (_openBlock(), _createBlock(_unref(ChevronRight), {
                                            key: 0,
                                            class: "flex-shrink-0 h-4 w-4 -mr-1"
                                          }))
                                        : _createCommentVNode("", true)
                                    ])
                                  ], 2)
                                ], 40, _hoisted_25))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_Transition, {
                        "enter-active-class": "transition duration-100 ease-out",
                        "enter-from-class": "opacity-0",
                        "enter-to-class": "opacity-100",
                        "leave-active-class": "transition duration-100 ease-in",
                        "leave-from-class": "opacity-0",
                        "leave-to-class": "opacity-0",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock("span", {
                            key: lastSectionText.value.key
                          }, _toDisplayString(lastSectionText.value.text), 1))
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_Transition, {
                      "enter-active-class": "transition duration-100 ease-out",
                      "enter-from-class": "opacity-0",
                      "enter-to-class": "opacity-100",
                      "leave-active-class": "transition duration-100 ease-in",
                      "leave-from-class": "opacity-0",
                      "leave-to-class": "opacity-0",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        (selectedActiveFarmIndex.value !== null && selectedActiveBarnIndex.value !== null && barnOptions.value[selectedActiveBarnIndex.value]._sections?.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationOptions.value[selectedActiveFarmIndex.value]?.barns[selectedActiveBarnIndex.value]?._sections, (section) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: section.id,
                                  role: "treeitem",
                                  "aria-label": section.name,
                                  "aria-level": "3",
                                  class: "flex items-center cursor-pointer",
                                  tabindex: "0"
                                }, [
                                  _createElementVNode("label", _hoisted_32, [
                                    _createVNode(CustomCheckbox, {
                                      checked: section.checked,
                                      "disable-transition": "",
                                      class: "flex-shrink-0",
                                      "onUpdate:checked": 
                                                    () => {
                                                        hasMadeSelection.value = true;
                                                        toggleSectionOrBatch(section.id);
                                                    }
                                                
                                    }, null, 8, ["checked", "onUpdate:checked"]),
                                    _createElementVNode("div", _hoisted_33, _toDisplayString(section.name), 1)
                                  ])
                                ], 8, _hoisted_31))
                              }), 128))
                            ]))
                          : (selectedActiveFarmIndex.value !== null && selectedActiveBarnIndex.value !== null && barnOptions.value[selectedActiveBarnIndex.value].batches?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationOptions.value[selectedActiveFarmIndex.value]?.barns[selectedActiveBarnIndex.value]?.batches, (batch) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: batch.barn.id,
                                    role: "treeitem",
                                    "aria-label": batch.name,
                                    "aria-level": "3",
                                    class: "flex items-center cursor-pointer",
                                    tabindex: "0"
                                  }, [
                                    _createElementVNode("label", _hoisted_36, [
                                      _createVNode(CustomCheckbox, {
                                        checked: batch.checked,
                                        "disable-transition": "",
                                        class: "flex-shrink-0",
                                        "onUpdate:checked": 
                                                    () => {
                                                        hasMadeSelection.value = true;
                                                        toggleSectionOrBatch(batch.id);
                                                    }
                                                
                                      }, null, 8, ["checked", "onUpdate:checked"]),
                                      _createElementVNode("div", _hoisted_37, [
                                        _createElementVNode("div", null, _toDisplayString(batch.name), 1),
                                        _createElementVNode("div", _hoisted_38, " (" + _toDisplayString(_ctx.$d(new Date(batch.start_date), 'short')) + _toDisplayString(batch.end_date ? ` - ${_ctx.$d(new Date(batch.end_date), 'short')}` : '') + ") ", 1)
                                      ])
                                    ])
                                  ], 8, _hoisted_35))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]),
        _createElementVNode("div", _hoisted_39, [
          _createVNode(CustomButton, {
            class: "w-auto min-w-40 px-5",
            disabled: !selectedLocationIds.value.length || isLoading.value,
            onClick: generateDashboard
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.analysis.buttons.generate')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_40, [
        _createVNode(_unref(Tabs), {
          teleport: "#analysis-tabs-target",
          "active-tab-class": "--active"
        }, {
          default: _withCtx(() => [
            _createElementVNode("nav", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.analysis.tabTitles.company'),
                  default: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(CompanyTab, {
                      "selected-analysis-type": fetchState.value.selectedAnalysisType,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "is-weaners": fetchState.value.isWeaners,
                      period: fetchState.value.period,
                      "include-complete-batch": fetchState.value.includeCompleteBatch,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-analysis-type", "selected-location-ids", "is-weaners", "period", "include-complete-batch", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.analysis.tabTitles.farm')
                }, {
                  default: _withCtx(() => [
                    _createVNode(FarmTab, {
                      "selected-analysis-type": fetchState.value.selectedAnalysisType,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "is-weaners": fetchState.value.isWeaners,
                      period: fetchState.value.period,
                      "include-complete-batch": fetchState.value.includeCompleteBatch,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-analysis-type", "selected-location-ids", "is-weaners", "period", "include-complete-batch", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.analysis.tabTitles.barn')
                }, {
                  default: _withCtx(() => [
                    _createVNode(BarnTab, {
                      "selected-analysis-type": fetchState.value.selectedAnalysisType,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "is-weaners": fetchState.value.isWeaners,
                      period: fetchState.value.period,
                      "include-complete-batch": fetchState.value.includeCompleteBatch,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-analysis-type", "selected-location-ids", "is-weaners", "period", "include-complete-batch", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["flex pr-4", { hidden: !hasSectionsModuleEnabled.value }])
              }, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.analysis.tabTitles.section')
                }, {
                  default: _withCtx(() => [
                    _createVNode(SectionTab, {
                      "selected-analysis-type": fetchState.value.selectedAnalysisType,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "is-weaners": fetchState.value.isWeaners,
                      period: fetchState.value.period,
                      "include-complete-batch": fetchState.value.includeCompleteBatch,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-analysis-type", "selected-location-ids", "is-weaners", "period", "include-complete-batch", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ], 2),
              _createElementVNode("div", _hoisted_45, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.analysis.tabTitles.batch')
                }, {
                  default: _withCtx(() => [
                    _createVNode(BatchTab, {
                      "selected-analysis-type": fetchState.value.selectedAnalysisType,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "is-weaners": fetchState.value.isWeaners,
                      period: fetchState.value.period,
                      "include-complete-batch": fetchState.value.includeCompleteBatch,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-analysis-type", "selected-location-ids", "is-weaners", "period", "include-complete-batch", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ])
            ])
          ]),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          id: "analysis-tabs-target",
          class: "mt-6"
        }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}
}

})