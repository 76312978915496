<template>
    <DropDown v-model:expand="expandDropdown" dropdown-class="mt-1 w-60">
        <template #dropdown-content>
            <button
                v-for="(setting, index) of settings"
                :key="setting.name"
                :tabindex="index"
                class="text-left w-full px-6 py-2 hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer"
                @click="setting.onSettingClicked()"
                @keydown.esc="expandDropdown = false"
                @keydown.enter="setting.onSettingClicked()"
            >
                <div class="py-2 font-medium" role="menuitem">
                    <div style="grid-template-columns: 2em auto" class="grid gap-2">
                        <div class="grid content-center">
                            <Icon :src="setting.icon"></Icon>
                        </div>
                        <span class="h-full text-gray-800 inline-flex items-center">{{ setting.name }}</span>
                    </div>
                </div>
            </button>
        </template>

        <button type="button" class="h-full w-full grid cursor-pointer text-left" @click="expandDropdown = !expandDropdown">
            <div style="grid-template-columns: auto 1.5em" class="w-full grid">
                <div style="grid-template-columns: 2em auto" class="grid gap-x-4">
                    <div class="grid content-center">
                        <UserInitialsIcon :user="user"></UserInitialsIcon>
                    </div>
                    <div class="grid grid-cols-1 pt-1">
                        <span class="text-white h-5 text-sm overflow-hidden">{{ $t('layouts.dashboard.topBar.settings.header') }} </span>
                        <span class="text-gray-300 text-opacity-60 text-xs overflow-hidden">{{ user?.email || '' }}</span>
                    </div>
                </div>
                <div class="content-center justify-end grid">
                    <ExpandIcon color="white" :expand="expandDropdown"></ExpandIcon>
                </div>
            </div>
        </button>
    </DropDown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import UserInitialsIcon from '@/components/layouts/dashboardLayout/topBar/settings/UserInitialsIcon.vue';
import Authentication from '@/plugins/authentication';
import { IconSource } from '@/types';

export default defineComponent({
    components: { DropDown, UserInitialsIcon, ExpandIcon },
    data() {
        return {
            expandDropdown: false,
        };
    },
    computed: {
        user() {
            return store.state.user;
        },
        hasActiveDirectory() {
            return store.getters.hasActiveDirectory;
        },
        hasAccessibleDirectories() {
            return store.getters.hasAccessibleDirectories;
        },
        settings() {
            const canManageDirectory = store.getters.loggedInUserCanManageDirectory;
            return [
                {
                    icon: IconSource.EditKPI,
                    name: this.$t('layouts.dashboard.topBar.settings.kpis'),
                    onSettingClicked: () => {
                        this.$router.push('/settings/kpis');
                        this.expandDropdown = false;
                    },
                    requiresManagePermissions: false,
                    disable: !this.hasActiveDirectory,
                },
                {
                    icon: IconSource.UserManagement,
                    name: this.$t('layouts.dashboard.topBar.settings.users'),
                    onSettingClicked: () => {
                        this.$router.push('/settings/users');
                        this.expandDropdown = false;
                    },
                    requiresManagePermissions: true,
                    disable: !this.hasActiveDirectory,
                },
                {
                    icon: IconSource.Farms,
                    name: this.$t('layouts.dashboard.topBar.settings.locations'),
                    onSettingClicked: () => {
                        this.$router.push('/settings/locations');
                        this.expandDropdown = false;
                    },
                    requiresManagePermissions: true,
                    disable: !this.hasActiveDirectory,
                },
                {
                    icon: IconSource.Enterprise,
                    name: this.$t('layouts.dashboard.topBar.settings.directories'),
                    onSettingClicked: () => {
                        this.$router.push('/settings/companies');
                        this.expandDropdown = false;
                    },
                    requiresManagePermissions: false,
                    disable: !this.hasAccessibleDirectories,
                },
                {
                    icon: IconSource.NavSettings,
                    name: this.$t('layouts.dashboard.topBar.settings.setup'),
                    onSettingClicked: () => {
                        this.$router.push('/settings/setup');
                        this.expandDropdown = false;
                    },
                    requiresManagePermissions: true,
                    disable: !this.hasActiveDirectory,
                },
                {
                    icon: IconSource.Cookie,
                    name: this.$t('layouts.dashboard.topBar.settings.cookies'),
                    onSettingClicked: () => {
                        this.$router.push('/settings/cookies');
                        this.expandDropdown = false;
                    },
                    disable: process.env.VUE_APP_COOKIE_BOT_DOMAIN_ID === undefined,
                    requiresManagePermissions: false,
                },
                {
                    icon: IconSource.Logout,
                    name: this.$t('layouts.dashboard.topBar.settings.logout'),
                    onSettingClicked: () => {
                        Authentication.logout(true);
                    },
                    requiresManagePermissions: false,
                },
            ].filter((currentSetting) => (canManageDirectory || !currentSetting.requiresManagePermissions) && !currentSetting.disable);
        },
    },
    created() {
        store.dispatch(ActionType.GetUser, { options: { skipLanguageCheck: true } });
    },
});
</script>
