import { computed, watch } from 'vue';
import isEqual from 'lodash-es/isEqual';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';

export default function useRefetchDataOnDashboardStateChanged(
    actionEntry: ActionType | ActionType[],
    options: { watchSites?: boolean; watchFarmsInsteadOfSites?: boolean; watchFormSubmissions?: boolean; watchPigType?: boolean }
) {
    const { watchSites = false, watchFarmsInsteadOfSites = false, watchFormSubmissions = false, watchPigType = false } = options;
    const siteIds = computed(() => (watchFarmsInsteadOfSites ? store.state.selectedFarmIds : (store.getters.getSelectedSiteIds as number[])));

    function dispatchAction(entry: ActionType | ActionType[]) {
        const actions = Array.isArray(entry) ? entry : [entry];

        actions.forEach((a) => {
            store.dispatch(a, { options: { ignoreCache: true } });
        });
    }

    watch(
        () => store.getters.getSelectedPeriod,
        (newValue, oldValue) => {
            if (newValue !== oldValue) {
                dispatchAction(actionEntry);
            }
        }
    );

    if (watchSites) {
        watch(siteIds, (newValue, oldValue) => {
            if (!isEqual(newValue, oldValue)) {
                dispatchAction(actionEntry);
            }
        });
    }

    if (watchFormSubmissions) {
        watch(
            () => store.state.formRecentlySubmitted,
            (newValue) => {
                if (newValue) {
                    dispatchAction(actionEntry);
                }
            }
        );
    }

    if (watchPigType) {
        watch(
            () => store.state.pigType,
            (newValue) => {
                if (newValue) {
                    dispatchAction(actionEntry);
                }
            }
        );
    }

    if (!watchSites || siteIds.value.length) {
        dispatchAction(actionEntry);
    }
}
