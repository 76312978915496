<template>
    <DashboardContentLayout class="space-y-8" :heading="hasAccessibleDirectories ? $t('views.settings.directories.header.heading') : ''" :has-period-picker="false" :has-pig-type-picker="false">
        <DirectoriesTable v-if="hasAccessibleDirectories" />
        <div v-else class="flex flex-col items-center justify-center h-full space-y-4 pt-6">
            <h1 class="text-4xl font-bold">{{ $t('views.settings.directories.header.noAccess') }}</h1>
            <p class="text-lg text-gray-600">{{ $t('views.settings.directories.content.noAccessText') }}</p>
        </div>
    </DashboardContentLayout>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import DirectoriesTable from '@/views/dashboard/settings/directories/DirectoriesTable.vue';
import { store } from '@/plugins/store';

const hasAccessibleDirectories = computed(() => store.getters.hasAccessibleDirectories);
</script>
