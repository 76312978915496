<template>
    <div>
        <FadeTransition mode="out-in">
            <div v-if="loading" key="loading" class="flex justify-center items-center px-6 py-12">
                <Spinner class="h-8 w-8" />
            </div>
            <div v-if="filteredItems.length" key="content" class="space-y-5">
                <div
                    v-for="item in filteredItems"
                    :key="item.farm_id"
                    class="border border-gray-300 rounded-lg px-4 py-5"
                    :class="{ 'expanded-farm': isExpanded(item.farm_id) }"
                    style="scroll-margin-top: 120px"
                >
                    <div class="flex items-center justify-between space-x-4">
                        <div class="flex items-center space-x-3">
                            <div>
                                <Icon class="h-6" :src="IconSource.FarmsPrimary"></Icon>
                            </div>
                            <div>
                                <div class="font-bold text-lg">{{ item.farm_name }}</div>
                                <div class="text-sm text-gray-500">
                                    {{ item.barns.length }} {{ item.barns.length === 1 ? $t('views.monitoring.main.headings.barn') : $t('views.monitoring.main.headings.barnPlural') }}, {{ sectionCount }}
                                    {{ sectionCount === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                                </div>
                            </div>
                        </div>
                        <div class="pr-1">
                            <button type="button" class="p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200" @click="toggleExpandFarm(item.farm_id)">
                                <Icon class="h-4 w-4 transform transition-transform rotate-90 cursor-pointer" :class="{ 'rotate-[270deg]': isExpanded(item.farm_id) }" :src="IconSource.Right"></Icon>
                            </button>
                        </div>
                    </div>
                    <ExpandCollapseTransition>
                        <div v-if="isExpanded(item.farm_id)" class="mt-5 space-y-5">
                            <WeightBarnSection v-for="barn in item.barns" :key="barn.headers.barn_id" :barn="barn" />
                        </div>
                    </ExpandCollapseTransition>
                </div>
            </div>
            <div v-else key="no-content" class="text-center px-4 py-14 text-gray-500 text-lg">{{ $t('views.monitoring.main.texts.noData') }}</div>
        </FadeTransition>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from 'vue';
import Icon from '@/components/icons/Icon.vue';
import { IconSource, SensorHistoryType, PopulatedMonitoringDataItem, PopulatedMonitoringDataBarn, PopulatedMonitoringDataRow } from '@/types';
import WeightBarnSection from './WeightBarnSection.vue';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { MONITORING_STATE } from '../injectionKeys';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
    components: { Icon, WeightBarnSection, ExpandCollapseTransition, FadeTransition, Spinner },
    props: {
        items: {
            type: Array as () => PopulatedMonitoringDataItem[],
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { expandedFarms, toggleExpandFarm } = inject(MONITORING_STATE) as any;

        const isExpanded = (farmId: number) => expandedFarms.value.includes(farmId);

        const filteredItems = computed(() => {
            const _filteredItems = props.items.map((i: PopulatedMonitoringDataItem) => {
                const filteredBarns = i.barns
                    .map((b: PopulatedMonitoringDataBarn) => {
                        const filteredRows = b.rows?.filter((r: PopulatedMonitoringDataRow) => Object.values(r.sensorHistory[SensorHistoryType.WEIGHT]).some((v) => v));
                        return {
                            ...b,
                            rows: filteredRows || null,
                        };
                    })
                    .filter((b) => Boolean(b.rows?.length));

                return {
                    ...i,
                    barns: filteredBarns,
                };
            });

            return _filteredItems.filter((i) => i.barns.length > 0);
        });

        const sectionCount = computed(() => filteredItems.value.reduce((acc, curr) => acc + curr.barns.reduce((acc2, curr2) => acc2 + (curr2.rows?.length || 0), 0), 0));

        return {
            IconSource,
            isExpanded,
            toggleExpandFarm,
            filteredItems,
            sectionCount,
        };
    },
});
</script>
