<template>
    <div class="grid grid-cols-3 xl:grid-cols-4 gap-4 mx-auto">
        <Kpi v-for="kpi of kpis" :key="kpi.index_id" :kpi="kpi"></Kpi>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Kpi from '@/views/dashboard/overview/Kpi.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

export default defineComponent({
    components: { Kpi },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetKpis, { watchSites: true, watchFarmsInsteadOfSites: true, watchFormSubmissions: true, watchPigType: true });
        store.dispatch(ActionType.GetKpiTypes);
    },
    computed: {
        kpis() {
            return store.state.kpis.sort((a, b) => a.index_id - b.index_id);
        },
    },
});
</script>
