import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex flex-col items-center justify-center h-full space-y-4 pt-6"
}
const _hoisted_2 = { class: "text-4xl font-bold" }
const _hoisted_3 = { class: "text-lg text-gray-600" }

import { computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import DirectoriesTable from '@/views/dashboard/settings/directories/DirectoriesTable.vue';
import { store } from '@/plugins/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const hasAccessibleDirectories = computed(() => store.getters.hasAccessibleDirectories);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardContentLayout, {
    class: "space-y-8",
    heading: hasAccessibleDirectories.value ? _ctx.$t('views.settings.directories.header.heading') : '',
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      (hasAccessibleDirectories.value)
        ? (_openBlock(), _createBlock(DirectoriesTable, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.directories.header.noAccess')), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('views.settings.directories.content.noAccessText')), 1)
          ]))
    ]),
    _: 1
  }, 8, ["heading"]))
}
}

})