<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.settings.kpis.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <div v-if="kpiPreferences" class="space-y-10">
            <div class="space-y-6">
                <h2 class="text-lg font-semibold">{{ $t('views.settings.kpis.content.headings.weaners') }}</h2>
                <EditableKpiGrid is-weaners />
            </div>
            <div class="space-y-6">
                <h2 class="text-lg font-semibold">{{ $t('views.settings.kpis.content.headings.slaughterPigs') }}</h2>
                <EditableKpiGrid />
            </div>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import EditableKpiGrid from '@/views/dashboard/settings/kpis/EditableKpiGrid.vue';

export default defineComponent({
    components: { DashboardContentLayout, EditableKpiGrid },
    setup() {
        store.dispatch(ActionType.GetKpiTypes);
        store.dispatch(ActionType.GetKpiPreferences);

        const kpiPreferences = computed(() => store.state.kpiPreferences);

        return { kpiPreferences };
    },
});
</script>
