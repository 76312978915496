import {
    Action,
    Farm,
    DirectoryFarm,
    Barn,
    Event,
    BatchEvent,
    SettingEvent,
    User,
    KpiType,
    KpiPreference,
    Kpi,
    RecordType,
    RawMaterial,
    Mix,
    Directory,
    NotificationResponse,
    RecordResponse,
    MixType,
    RawMaterialRegisterItem,
    Meta,
    MixRegisterItem,
    UserRole,
    DirectoryRole,
    DirectoryPermission,
    DirectoryUser,
    DirectoryBarn,
    Section,
    EconomyPostingsResponse,
    Language,
    DirectoryBatch,
    EconomyPostingCategory,
    EconomyStatusEntry,
    EconomyListing,
    EconomyPostPayment,
    EconomyBudgetEntry,
    EconomyEvent,
    EconomyDeadPayment,
    ConfigEvent,
    PigType,
    DirectorySection,
    AssignmentType,
    AssignmentResponse,
    HealthDrugs,
    TreatmentProcedure,
    Treatment,
    TreatmentHistory,
    MiscEvent,
    AssignmentTemplate,
    MonitoringData,
    AlarmType,
    AlarmResponse,
    AlarmCause,
    NoteResponse,
    DataQualityAssessment,
    DataQualityCategory,
    DataQualityAssessmentMethod,
    Dashboard,
    AnalysisType,
} from '@/types';
import { ActionType } from '../actions';

export type State = {
    farms: Farm[];
    directoryFarms: DirectoryFarm[];
    directoryBarns: DirectoryBarn[];
    directorySections: DirectorySection[];
    sections: null | Section[];
    barns: Barn[];
    batches: DirectoryBatch[];
    events: Event[];
    batchEvents: BatchEvent[];
    settingEvents: SettingEvent[];
    economyEvents: EconomyEvent[];
    configEvents: ConfigEvent[];
    miscEvents: MiscEvent[];
    actions: Action[];
    notificationResponse: NotificationResponse | null;
    noteResponse: NoteResponse | null;
    assignmentResponse: AssignmentResponse | null;
    assignmentTypes: AssignmentType[];
    user: User | null;
    userRole: UserRole | null;
    activeDirectory: string | null;
    languages: Language[];
    authToken: string;
    inProgressActions: ActionType[];
    sitesAreLoaded: boolean;
    kpiTypes: KpiType[];
    kpiPreferences: { is_weaner: boolean; directory_default: boolean; kpis: KpiPreference }[] | null;
    kpis: Kpi[];
    recordResponse: RecordResponse | null;
    recordTypes: RecordType[];
    economyPostingsResponse: null | EconomyPostingsResponse;
    economyPostingCategories: EconomyPostingCategory[];
    economyStatusEntries: EconomyStatusEntry[];
    economyListings: EconomyListing[];
    economyPostPayments: EconomyPostPayment[];
    economyBudgetEntries: EconomyBudgetEntry[];
    economyDeadPayments: EconomyDeadPayment[];
    healthDrugs: HealthDrugs[];
    rawMaterials: RawMaterial[];
    rawMaterialRegisterItems: RawMaterialRegisterItem[];
    mixes: Mix[];
    mixTypes: MixType[];
    mixRegisterItems: MixRegisterItem[];
    directories: Directory[];
    directoryRoles: DirectoryRole[];
    directoryPermissions: DirectoryPermission[];
    directoryUsers: DirectoryUser[];
    meta: Meta | null;
    footer: null | { footer_html: string };
    icons: null | { [key: string]: string };
    pigTypePickerOptions: {
        weanersWidth: number;
        slaughterPigsWidth: number;
    };
    pigType: PigType;
    selectedFarmIds: number[];
    selectedBarnIds: number[];
    selectedBatchIds: number[];
    selectedSectionIds: number[];
    periodPickerDateString: string;
    dashboardLoaded: boolean;
    formRecentlySubmitted: boolean;
    treatmentProcedures: TreatmentProcedure[];
    treatments: Treatment[];
    treatmentHistory: TreatmentHistory | null;
    assignmentTemplates: AssignmentTemplate[];
    monitoringData: MonitoringData | null;
    isMonitoringDataLoading: boolean;
    alarmResponse: AlarmResponse | null;
    alarmTypes: AlarmType[] | null;
    alarmCauses: AlarmCause[] | null;
    dataQualityAssessment: DataQualityAssessment | null;
    dataQualityCategories: DataQualityCategory[] | null;
    dataQualityAssessmentMethods: DataQualityAssessmentMethod[] | null;
    dashboardOverview: Dashboard | null;
    dashboardOverviewLoading: boolean;
    debugDashboardOverview: Dashboard | null;
    debugDashboardOverviewLoading: boolean;
    analysisTypes: AnalysisType[];
    analysisDashboardState: {
        selectedAnalysisTypeId: number | null;
        selectedLocationIds: number[];
    };
};

export const state: State = {
    // API-supplied states
    farms: [],
    directoryFarms: [],
    directoryBarns: [],
    directorySections: [],
    sections: null,
    barns: [],
    batches: [],
    events: [],
    batchEvents: [],
    settingEvents: [],
    economyEvents: [],
    configEvents: [],
    miscEvents: [],
    actions: [],
    notificationResponse: null,
    noteResponse: null,
    assignmentResponse: null,
    assignmentTypes: [],
    user: null,
    userRole: null,
    activeDirectory: null,
    languages: [],
    authToken: '',
    inProgressActions: [],
    sitesAreLoaded: false,
    kpiTypes: [],
    kpiPreferences: null,
    kpis: [],
    recordResponse: null,
    recordTypes: [],
    economyPostingsResponse: null,
    economyPostingCategories: [],
    economyStatusEntries: [],
    economyListings: [],
    economyPostPayments: [],
    economyBudgetEntries: [],
    economyDeadPayments: [],
    healthDrugs: [],
    rawMaterials: [],
    rawMaterialRegisterItems: [],
    mixes: [],
    mixTypes: [],
    mixRegisterItems: [],
    directories: [],
    directoryRoles: [],
    directoryPermissions: [],
    directoryUsers: [],
    meta: null,
    footer: null,
    icons: null,

    // User-selected states
    pigTypePickerOptions: {
        weanersWidth: 0,
        slaughterPigsWidth: 0,
    },
    pigType: PigType.SlaughterPigs,
    selectedFarmIds: [],
    selectedBarnIds: [],
    selectedBatchIds: [],
    selectedSectionIds: [],
    periodPickerDateString: '',
    dashboardLoaded: false,
    formRecentlySubmitted: false,
    treatmentProcedures: [],
    treatments: [],
    treatmentHistory: null,
    assignmentTemplates: [],
    monitoringData: null,
    isMonitoringDataLoading: false,
    alarmResponse: null,
    alarmTypes: null,
    alarmCauses: null,
    dataQualityAssessment: null,
    dataQualityCategories: null,
    dataQualityAssessmentMethods: null,
    dashboardOverview: null,
    dashboardOverviewLoading: false,
    debugDashboardOverview: null,
    debugDashboardOverviewLoading: false,
    analysisTypes: [],
    analysisDashboardState: {
        selectedAnalysisTypeId: null,
        selectedLocationIds: [],
    },
};
