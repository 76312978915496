<template>
    <div>
        <div class="monitoring-tabs default-tabs">
            <Tabs teleport="#monitoring-tabs-target" active-tab-class="--active" @rewrite="onTabChange">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.monitoring.tabTitles.overview')" :default="true">
                            <OverviewTab :items="filteredItems" :loading="isLoading" />
                        </Tab>
                    </div>
                    <div class="flex pr-4">
                        <Tab :name="$t('views.monitoring.tabTitles.water')">
                            <WaterTab :items="filteredItems" :loading="isLoading" />
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.monitoring.tabTitles.food')">
                            <FoodTab :items="filteredItems" :loading="isLoading" />
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.monitoring.tabTitles.weight')">
                            <WeightTab :items="filteredItems" :loading="isLoading" />
                        </Tab>
                    </div>
                </nav>
            </Tabs>
            <div id="monitoring-tabs-target" class="mt-6" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, provide, nextTick } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { subMonths, subYears } from 'date-fns';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ChartPeriod, PopulatedMonitoringDataItem, MonitoringDataHeaders, PopulatedMonitoringDataBarn, SensorHistoryType, SensorLocationHistoryEntry, SensorType } from '@/types';
import OverviewTab from './overview/OverviewTab.vue';
import WaterTab from './water/WaterTab.vue';
import FoodTab from './food/FoodTab.vue';
import WeightTab from './weight/WeightTab.vue';
import { MONITORING_STATE } from './injectionKeys';
import { isElementInViewport } from '@/util/elements';
import { useSensorLocationHistory } from '@/plugins/store/actions/queries/sensor-location-history';

export default defineComponent({
    components: { Tab, Tabs, OverviewTab, WaterTab, FoodTab, WeightTab },
    props: {
        searchQuery: {
            type: String as () => string,
            default: '',
        },
    },
    setup(props) {
        useRefetchDataOnDashboardStateChanged(ActionType.GetMonitoringData, { watchSites: true, watchFormSubmissions: true });

        const isLoading = computed(() => store.state.isMonitoringDataLoading);

        const { data: sensorLocationHistory, isLoading: isSensorLocationHistoryLoading } = useSensorLocationHistory();

        const sensorHistoryIntervals = computed(() => {
            if (!sensorLocationHistory.value) {
                return [];
            }

            const sensorHistoryBySensor: Record<number, SensorLocationHistoryEntry[]> = {};

            for (const record of sensorLocationHistory.value) {
                if (!sensorHistoryBySensor[record.sensor_id]) {
                    sensorHistoryBySensor[record.sensor_id] = [];
                }

                sensorHistoryBySensor[record.sensor_id].push(record);
            }

            for (const sensorId in sensorHistoryBySensor) {
                if (sensorHistoryBySensor[sensorId]) {
                    sensorHistoryBySensor[sensorId].sort((a: SensorLocationHistoryEntry, b: SensorLocationHistoryEntry) => new Date(a.from).getTime() - new Date(b.from).getTime());
                }
            }

            const intervals: {
                sensorId: string;
                sensorType: SensorType;
                sectionId: number;
                fromDate: string;
                toDate: string | null;
            }[] = [];

            for (const sensorId in sensorHistoryBySensor) {
                if (sensorHistoryBySensor[sensorId]) {
                    const records = sensorHistoryBySensor[sensorId];

                    for (let i = 0; i < records.length; i++) {
                        const record = records[i];
                        const fromDate = record.from;
                        let toDate = null;

                        if (i + 1 < records.length) {
                            // Next record exists; sensor moved to another section
                            toDate = records[i + 1].from;
                        }

                        intervals.push({
                            sensorId,
                            sensorType: record.sensor_type_id,
                            sectionId: record.location_id,
                            fromDate,
                            toDate,
                        });
                    }
                }
            }

            return intervals;
        });

        function hasSensorHistory({ sectionId, periodStart, periodEnd, sensorType }: { sectionId: number; periodStart: Date; periodEnd: Date; sensorType?: SensorType }) {
            // Filter intervals for the given section
            const sectionIntervals = sensorHistoryIntervals.value.filter((i) => (sensorType !== undefined ? i.sensorType === sensorType : true) && i.sectionId === sectionId);

            // Check for overlap with the given period
            for (const interval of sectionIntervals) {
                const intervalStart = new Date(interval.fromDate);
                const intervalEnd = interval.toDate || periodEnd; // Use periodEnd if toDate is null

                // Overlap exists
                if (intervalStart <= periodEnd && intervalEnd >= periodStart) {
                    return true;
                }
            }

            return false;
        }

        const items = computed<PopulatedMonitoringDataItem[]>(() => {
            if (!store.state.monitoringData || !sensorLocationHistory.value || isSensorLocationHistoryLoading.value) {
                return [];
            }

            const mappedItems: {
                farm_id: number;
                farm_name?: string | null;
                barn_id: number;
                headers: MonitoringDataHeaders;
                barns: PopulatedMonitoringDataBarn[];
                sectionCount?: number;
            }[] = [];

            const now = new Date();

            const periodOptions = {
                [ChartPeriod.THREE_MONTHS]: subMonths(now, 3),
                [ChartPeriod.SIX_MONTHS]: subMonths(now, 6),
                [ChartPeriod.NINE_MONTHS]: subMonths(now, 9),
                [ChartPeriod.TWELVE_MONTHS]: subYears(now, 1),
            };

            for (const farm of store.state.farms) {
                let existingFarm = mappedItems.find((i) => i.farm_id === farm.farm_id);
                const barns = store.state.monitoringData.filter((b) => b.headers.farm_id === farm.farm_id);

                for (const barn of barns) {
                    const rows = barn.rows?.map((r) => ({
                        ...r,
                        sensorHistory: {
                            [SensorHistoryType.OVERVIEW]: {
                                [ChartPeriod.THREE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.THREE_MONTHS], periodEnd: now }),
                                [ChartPeriod.SIX_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.SIX_MONTHS], periodEnd: now }),
                                [ChartPeriod.NINE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.NINE_MONTHS], periodEnd: now }),
                                [ChartPeriod.TWELVE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.TWELVE_MONTHS], periodEnd: now }),
                            },
                            [SensorHistoryType.WATER]: {
                                [ChartPeriod.THREE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.THREE_MONTHS], periodEnd: now, sensorType: SensorType.WATER }),
                                [ChartPeriod.SIX_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.SIX_MONTHS], periodEnd: now, sensorType: SensorType.WATER }),
                                [ChartPeriod.NINE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.NINE_MONTHS], periodEnd: now, sensorType: SensorType.WATER }),
                                [ChartPeriod.TWELVE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.TWELVE_MONTHS], periodEnd: now, sensorType: SensorType.WATER }),
                            },
                            [SensorHistoryType.FEEDING]: {
                                [ChartPeriod.THREE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.THREE_MONTHS], periodEnd: now, sensorType: SensorType.FEEDING }),
                                [ChartPeriod.SIX_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.SIX_MONTHS], periodEnd: now, sensorType: SensorType.FEEDING }),
                                [ChartPeriod.NINE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.NINE_MONTHS], periodEnd: now, sensorType: SensorType.FEEDING }),
                                [ChartPeriod.TWELVE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.TWELVE_MONTHS], periodEnd: now, sensorType: SensorType.FEEDING }),
                            },
                            [SensorHistoryType.WEIGHT]: {
                                [ChartPeriod.THREE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.THREE_MONTHS], periodEnd: now, sensorType: SensorType.WEIGHT }),
                                [ChartPeriod.SIX_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.SIX_MONTHS], periodEnd: now, sensorType: SensorType.WEIGHT }),
                                [ChartPeriod.NINE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.NINE_MONTHS], periodEnd: now, sensorType: SensorType.WEIGHT }),
                                [ChartPeriod.TWELVE_MONTHS]: hasSensorHistory({ sectionId: r.section_id, periodStart: periodOptions[ChartPeriod.TWELVE_MONTHS], periodEnd: now, sensorType: SensorType.WEIGHT }),
                            },
                        },
                    }));

                    const populatedBarn: PopulatedMonitoringDataBarn = {
                        ...barn,
                        rows: rows || null,
                    };

                    if (existingFarm) {
                        existingFarm.barns.push(populatedBarn);
                    } else {
                        existingFarm = {
                            farm_id: barn.headers.farm_id,
                            farm_name: barn.headers.farm_name,
                            barn_id: barn.headers.barn_id,
                            headers: barn.headers,
                            barns: [populatedBarn],
                        };

                        mappedItems.push(existingFarm);
                    }
                }
            }

            return mappedItems.filter((item) => Boolean(item.barns?.length));
        });
        const filteredItems = computed(() => {
            if (!props.searchQuery) {
                return items.value;
            }

            const lowerCaseSearchQuery = props.searchQuery.toLowerCase();

            return items.value.filter(
                (i) =>
                    i.farm_name?.toLowerCase().includes(lowerCaseSearchQuery) ||
                    i.barns.some(
                        (b: PopulatedMonitoringDataBarn) => b.headers.barn_name?.toLowerCase().includes(lowerCaseSearchQuery) || b.rows?.some((r) => r.section_name?.toLowerCase().includes(lowerCaseSearchQuery))
                    )
            );
        });

        const expandedFarms = ref<number[]>([]);
        const expandedBarns = ref<number[]>([]);

        function toggleExpandFarm(farmId: number) {
            if (expandedFarms.value.includes(farmId)) {
                expandedFarms.value = expandedFarms.value.filter((id) => id !== farmId);
            } else {
                expandedFarms.value.push(farmId);
            }
        }

        function toggleExpandBarn(barnId: number) {
            if (expandedBarns.value.includes(barnId)) {
                expandedBarns.value = expandedBarns.value.filter((id) => id !== barnId);
            } else {
                expandedBarns.value.push(barnId);
            }
        }

        provide(MONITORING_STATE, {
            expandedFarms,
            expandedBarns,
            toggleExpandFarm,
            toggleExpandBarn,
        });

        async function onTabChange() {
            await nextTick();

            setTimeout(() => {
                const firstExpandedFarm = document.querySelector('.expanded-farm');

                if (firstExpandedFarm) {
                    const firstExpandedBarn = firstExpandedFarm.querySelector('.expanded-barn');

                    if (firstExpandedBarn && !isElementInViewport(firstExpandedBarn)) {
                        firstExpandedBarn.scrollIntoView({ behavior: 'smooth' });
                    } else if (!firstExpandedBarn && !isElementInViewport(firstExpandedFarm)) {
                        firstExpandedFarm.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }, 300);
        }

        return {
            items,
            filteredItems,
            expandedFarms,
            expandedBarns,
            onTabChange,
            isLoading,
        };
    },
});
</script>
