import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: "loading",
  class: "flex justify-center items-center px-6 py-12"
}
const _hoisted_2 = {
  key: "content",
  class: "space-y-5"
}
const _hoisted_3 = { class: "flex items-center justify-between space-x-4" }
const _hoisted_4 = { class: "flex items-center space-x-3" }
const _hoisted_5 = { class: "font-bold text-lg" }
const _hoisted_6 = { class: "text-sm text-gray-500" }
const _hoisted_7 = { class: "pr-1" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "mt-5 space-y-5"
}
const _hoisted_10 = {
  key: "no-content",
  class: "text-center px-4 py-14 text-gray-500 text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FoodBarnSection = _resolveComponent("FoodBarnSection")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FadeTransition, { mode: "out-in" }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_Spinner, { class: "h-8 w-8" })
            ]))
          : _createCommentVNode("", true),
        (_ctx.filteredItems.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.farm_id,
                  class: _normalizeClass(["border border-gray-300 rounded-lg px-4 py-5", { 'expanded-farm': _ctx.isExpanded(item.farm_id) }]),
                  style: {"scroll-margin-top":"120px"}
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_Icon, {
                          class: "h-6",
                          src: _ctx.IconSource.FarmsPrimary
                        }, null, 8, ["src"])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(item.farm_name), 1),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(item.barns.length) + " " + _toDisplayString(item.barns.length === 1 ? _ctx.$t('views.monitoring.main.headings.barn') : _ctx.$t('views.monitoring.main.headings.barnPlural')) + ", " + _toDisplayString(_ctx.sectionCount) + " " + _toDisplayString(_ctx.sectionCount === 1 ? _ctx.$t('views.monitoring.main.headings.section') : _ctx.$t('views.monitoring.main.headings.sectionPlural')), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200",
                        onClick: ($event: any) => (_ctx.toggleExpandFarm(item.farm_id))
                      }, [
                        _createVNode(_component_Icon, {
                          class: _normalizeClass(["h-4 w-4 transform transition-transform rotate-90 cursor-pointer", { 'rotate-[270deg]': _ctx.isExpanded(item.farm_id) }]),
                          src: _ctx.IconSource.Right
                        }, null, 8, ["class", "src"])
                      ], 8, _hoisted_8)
                    ])
                  ]),
                  _createVNode(_component_ExpandCollapseTransition, null, {
                    default: _withCtx(() => [
                      (_ctx.isExpanded(item.farm_id))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.barns, (barn) => {
                              return (_openBlock(), _createBlock(_component_FoodBarnSection, {
                                key: barn.headers.barn_id,
                                barn: barn
                              }, null, 8, ["barn"]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ], 2))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('views.monitoring.main.texts.noData')), 1))
      ]),
      _: 1
    })
  ]))
}