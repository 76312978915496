<template>
    <div class="sidebar flex overflow-hidden max-w-dashboard mx-auto">
        <div v-if="hasActiveDirectory" class="fixed top-24 h-full z-20 flex flex-shrink-0 bg-gray-100">
            <div
                class="flex flex-col border-r border-gray-300 transition-width duration-300"
                :class="{ 'w-[270px]': !isMenuCollapsed || (isMenuCollapsed && isHovered), 'w-[60px]': isMenuCollapsed && !isHovered }"
                @mouseover="isHovered = true"
                @mouseout="isHovered = false"
            >
                <div class="flex flex-col h-0 flex-1">
                    <div class="navbar-container flex-1 flex flex-col space-y-8 overflow-y-auto overflow-x-hidden">
                        <nav class="py-8 space-y-1 divide-y divide-gray-300">
                            <div v-for="(sidebarGroup, groupIndex) of sidebarRouteGroups" :key="groupIndex" class="py-3">
                                <div v-for="(sidebarRoute, routeIndex) of sidebarGroup" :key="routeIndex">
                                    <router-link
                                        :to="sidebarRoute.path"
                                        :class="{
                                            'text-gray-500 font-light border-l border-transparent': $route.path !== sidebarRoute.path,
                                            'text-black font-medium bg-primary-700 bg-opacity-10 border-l border-primary-700': $route.path === sidebarRoute.path,
                                        }"
                                        style="grid-template-columns: 27px auto 27px"
                                        class="w-full transition-colors tracking-tight grid items-center px-4 py-4 gap-x-2 h-[56px]"
                                    >
                                        <Icon :src="$route.path === sidebarRoute.path || (hasChildrenActive(sidebarRoute.children) && !isHovered) ? sidebarRoute.activeIcon : sidebarRoute.inactiveIcon"></Icon>
                                        <span class="transition-opacity duration-300 whitespace-nowrap" :class="!isMenuCollapsed || isHovered ? 'opacity-100' : 'opacity-0 hidden'">
                                            {{ $t(sidebarRoute.text) || sidebarRoute.name }}
                                        </span>
                                        <span v-if="Array.isArray(sidebarRoute.children) && sidebarRoute.children.length && (!isMenuCollapsed || isHovered)" class="justify-self-end">
                                            <ExpandIcon :expand="sidebarRoute.expanded" color="black"></ExpandIcon>
                                        </span>
                                    </router-link>
                                    <div
                                        :style="sidebarRoute.expanded && (!isMenuCollapsed || isHovered) ? `max-height: ${40 * sidebarRoute.children.length}px;` : 'max-height: 0px;'"
                                        class="overflow-hidden"
                                        style="transition: max-height 0.25s"
                                    >
                                        <div v-for="(sidebarRouteChild, routeChildIndex) of sidebarRoute.children" :key="routeChildIndex">
                                            <router-link
                                                :tabindex="sidebarRoute.expanded ? null : '-1'"
                                                :to="sidebarRouteChild.path"
                                                :class="$route.path === sidebarRouteChild.path ? 'text-black font-medium bg-primary-700 bg-opacity-10 border-l border-primary-700' : 'text-gray-500 font-light'"
                                                style="grid-template-columns: 27px auto 27px"
                                                class="w-full grid tracking-tight items-center px-4 py-2 gap-x-2"
                                            >
                                                <span></span>
                                                <span class="whitespace-nowrap">
                                                    {{ $t(sidebarRouteChild.text) || sidebarRouteChild.name }}
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div>
                            <button class="w-full text-sm text-gray-500 h-12 flex items-center px-4 py-3 hover:bg-gray-200 rounded-l-md transition-colors" @click="toggleMenuCollapsed">
                                <ExpandIcon class="flex-shrink-0 transform mx-2 opacity-50" :class="isMenuCollapsed ? '-rotate-90' : 'rotate-90'" color="black"></ExpandIcon>
                                <span v-show="!isMenuCollapsed || isHovered" class="whitespace-nowrap">{{
                                    isMenuCollapsed ? $t('layouts.dashboard.sideBar.buttons.expandMenu') : $t('layouts.dashboard.sideBar.buttons.collapseMenu')
                                }}</span>
                            </button>
                        </div>
                        <div class="w-full pb-4 transition-opacity transition-width duration-300" :class="{ 'opacity-0 w-0': !isLogoShown && !isHovered, 'w-full': isLogoShown || isHovered }">
                            <Icon class="w-36 pl-2.75" :src="SIDEBAR_LOGO_SOURCE"></Icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full mt-24 transition-margin-left duration-300" :class="{ 'ml-[60px]': isMenuCollapsed && hasActiveDirectory, 'ml-[270px]': !isMenuCollapsed && hasActiveDirectory }">
            <slot></slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { routes } from '@/plugins/router/routes';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { store } from '@/plugins/store';
import { Module } from '@/types';

interface SidebarRouteData {
    name: string | symbol | undefined;
    path: string;
    children: SidebarRouteData[];
    sidebarGroup: number | undefined;
    activeIcon: string | undefined;
    inactiveIcon: string | undefined;
    expanded: boolean;
    text: string | undefined;
}

export default defineComponent({
    components: { ExpandIcon },
    data() {
        return {
            SIDEBAR_LOGO_SOURCE: `/solutions/${process.env.VUE_APP_SOLUTION_PATH}/assets/images/sidebar-logo.svg`,
            isMenuCollapsed: window.localStorage ? localStorage.getItem('menu-collapsed') === 'true' : false,
            isHovered: false,
            isLogoShown: window.localStorage ? localStorage.getItem('menu-collapsed') !== 'true' : true,
        };
    },
    computed: {
        sidebarRouteGroups(): SidebarRouteData[][] {
            const DASHBOARD_ROUTE_NAME = 'Dashboard';
            const dashboardRoutes = routes.find((currentRoute) => currentRoute.name === DASHBOARD_ROUTE_NAME)?.children;
            if (!dashboardRoutes || dashboardRoutes.length === 0) {
                return [];
            }

            const sidebarRouteGroups = [
                ...dashboardRoutes
                    .filter(
                        (currentRoute) =>
                            currentRoute.meta?.appearsInSidebar &&
                            (typeof currentRoute.meta?.hideIfNotGetter !== 'string' || (store.getters as any)[currentRoute.meta?.hideIfNotGetter]) &&
                            (typeof currentRoute.meta?.requiresModule !== 'string' || store.getters.hasModuleEnabled(currentRoute.meta?.requiresModule as Module))
                    )
                    .map((currentSidebarRoute) => this.getRouteDataFromRoute(currentSidebarRoute))
                    .reduce((accum: Map<number, SidebarRouteData[]>, current: SidebarRouteData) => {
                        if (!current.sidebarGroup) {
                            return accum;
                        }
                        if (!Array.isArray(accum.get(current.sidebarGroup))) {
                            accum.set(current.sidebarGroup, []);
                        }
                        (accum.get(current.sidebarGroup) as SidebarRouteData[]).push(current);

                        return accum;
                    }, new Map()),
            ].map((sidebarGroup) => sidebarGroup[1]);

            return sidebarRouteGroups;
        },
        hasActiveDirectory() {
            return store.getters.hasActiveDirectory;
        },
    },
    methods: {
        getRouteDataFromRoute(route: RouteRecordRaw): SidebarRouteData {
            return {
                name: route.name,
                path: route.path,
                children:
                    route.children
                        ?.filter(
                            (currentChildRoute) =>
                                currentChildRoute.meta?.appearsInSidebar &&
                                (typeof currentChildRoute.meta?.hideIfNotGetter !== 'string' || (store.getters as any)[currentChildRoute.meta?.hideIfNotGetter]) &&
                                (typeof currentChildRoute.meta?.requiresModule !== 'string' || store.getters.hasModuleEnabled(currentChildRoute.meta?.requiresModule as Module))
                        )
                        .map((currentChildRoute) => this.getRouteDataFromRoute(currentChildRoute)) || [],
                sidebarGroup: route.meta?.sidebarGroup as number | undefined,
                text: route.meta?.sidebarText as string | undefined,
                activeIcon: route.meta?.sidebarActiveIcon as string | undefined,
                inactiveIcon: route.meta?.sidebarInactiveIcon as string | undefined,
                expanded: this.$route.path === route.path || !!(route.children && route.children.map((currentChildRoute) => currentChildRoute.path).includes(this.$route.path)),
            };
        },
        toggleMenuCollapsed() {
            this.isMenuCollapsed = !this.isMenuCollapsed;

            if (window.localStorage) {
                localStorage.setItem('menu-collapsed', this.isMenuCollapsed.toString());
            }

            setTimeout(() => {
                this.isLogoShown = !this.isMenuCollapsed;

                window.dispatchEvent(new Event('resize'));
            }, 300);
        },
        hasChildrenActive(children: SidebarRouteData[]) {
            return children.some((child) => child.path === this.$route.path);
        },
    },
});
</script>

<style>
.sidebar {
    min-height: calc(100vh - 6rem);
}
.navbar-container {
    max-height: calc(100vh - 6rem);
}
</style>
